import { Grid } from "@mui/material";
import LocalizationDetails from "components/base/Settings/General-Information/localization";
import { PrivatePageLayout } from "components/common/layouts/Page/private";

export function LocalizationSettingsPage() {
    return <PrivatePageLayout
        link={"https://images.pexels.com/photos/14184926/pexels-photo-14184926.jpeg?auto=compress&cs=tinysrgb"}
        activeLink={"Settings"}
        activeSubLink={"Localization Information"}
        title="Localization Information"
    >
        <Grid container style={{ maxWidth: "500px", margin: 'auto' }} spacing={0}>
            <Grid item xs={12}>
                <LocalizationDetails />
            </Grid>
        </Grid>
    </PrivatePageLayout>
}