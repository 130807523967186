import { Grid, TextField } from "@mui/material";
import { useState } from "react";
import BaseDialogComponent from "components/common/layouts/Dialog";

export default function EditMediaModal({mediaSrc, isOpen, closeFunction, deleteFunction}: 
    {mediaSrc: string, isOpen: boolean, closeFunction: any, deleteFunction: any}) {
    
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")

    function updateFunction() {

    }

    return <BaseDialogComponent 
        isOpen={isOpen} 
        closeFunction={closeFunction} 
        title={"Edit Media"} 
        deletFunction={deleteFunction} 
        updateFunction={updateFunction}
    >
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <TextField fullWidth label={"Title"} value={title} onChange={(e)=>{setTitle(e?.target?.value)}} />
            </Grid>
            <Grid item xs={12}>
                <TextField fullWidth label={"Description"} value={description} onChange={(e)=>{setDescription(e?.target?.value)}} />
            </Grid>
            <Grid item xs={12}>
                <img src={mediaSrc} alt={description} style={{maxWidth: "100%"}} />
            </Grid>
        </Grid>
    </BaseDialogComponent>
}