import { Grid, TextField, Button, Checkbox, Typography } from "@mui/material";
import { PasswordField } from "components/common/ui/TextFields/Password-Field";
import { browserSessionPersistence, inMemoryPersistence, setPersistence, signInWithEmailAndPassword } from "firebase/auth";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { auth } from "utilities/Firebase/firebase-redux";

export default function LoginForm() {
    const historyRedux = useSelector((state: any) => state.history);
    const navigate = useNavigate()

    const [password, setPassword] = useState('')
    const [email, setEmail] = useState('')
    const [isSaveLogInSelected, setSaveLogInIsSelected] = useState(false)

    function login(e: any) {
        e.preventDefault();
        setPersistence(auth, isSaveLogInSelected ? browserSessionPersistence : inMemoryPersistence).then(() => {
            signInWithEmailAndPassword(auth, email, password).then((res) => {
                toast.success("Successful Login", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                const oldPath = `${historyRedux?.pathname}${historyRedux?.search}`
                navigate(oldPath === "" ? '/' : oldPath)
            }).catch((err) => {
                toast.error("There was an Error logging you in. Please try again", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            })
        }).catch((err) => {
            toast.error("There was an Error setting Persistence. Please try again", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        })
    }

    return <form style={{ margin: 'auto' }} onSubmit={login}>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField variant="standard" required type="email" label="Email-Address" fullWidth value={email} onChange={(e: any) => { setEmail(e.target?.value) }} />
            </Grid>
            <Grid item xs={12}>
                <PasswordField setPassword={setPassword} label={"Password"} required />
            </Grid>
            <Grid item xs={12}>
                <Typography onClick={() => { setSaveLogInIsSelected(!isSaveLogInSelected) }} style={{ margin: 0, textAlign: 'start' }}>
                    <Checkbox checked={isSaveLogInSelected} /> Stay Logged In
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Button fullWidth type="submit" variant="contained">Login</Button>
            </Grid>
        </Grid>
    </form>
}