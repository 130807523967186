import { Grid, Paper, Stack, Switch, TextField, Typography } from "@mui/material"
import { TimePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { MaxTimeType } from "../edit-ticket-group-dialog";

export function TimingSelect({ title, values, onChange, name }:
    { title: string, values: MaxTimeType, onChange: any, name: string }) {

    function changeDays(newDays: number) {
        const newObj: MaxTimeType = {
            ...values,
            date: {
                dateTime: values?.date?.dateTime || 0,
                days: newDays
            }
        }
        onChange(newObj)
    }

    function changeDateTime(newDate: Dayjs) {
        const newObj: MaxTimeType = {
            ...values,
            date: {
                dateTime: newDate?.toDate()?.getTime() || 0,
                days: values?.date?.days || 0
            }
        }
        onChange(newObj)
    }

    function changeIsDateSelected(newValues: boolean) {
        const newObj: MaxTimeType = {
            ...values,
            isDateSelected: newValues
        }
        onChange(newObj)
    }

    function changeHours(newHours: Dayjs) {
        const newObj: MaxTimeType = {
            ...values,
            hours: newHours?.toDate()?.getTime() || 0,
        }
        onChange(newObj)
    }

    return <Paper style={{ padding: '0.5rem' }}>
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography typography={"h6"}>{title}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Stack direction="row" spacing={1} alignItems="center">
                    <Typography>Stunden</Typography>
                    <Switch checked={values?.isDateSelected || false} onClick={() => { changeIsDateSelected(!values?.isDateSelected || false); }} />
                    <Typography>Datum</Typography>
                </Stack>
            </Grid>
            {(!values?.isDateSelected || false) &&
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        value={Number(dayjs(values?.hours || 0).tz("Europe/Berlin").toDate().getTime() / 60 / 60 / 1000).toString()}
                        onChange={(e: any) => { changeHours(dayjs(Number((e?.target?.value)) * 60 * 60 * 1000).tz("Europe/Berlin")); }}
                        name={name}
                        label={"Zeit in Stunden"}
                        type="number"
                    />
                </Grid>}
            {(values?.isDateSelected || false) && <>
                <Grid item xs={12}>
                    <TextField
                        label="x Tage vorher"
                        fullWidth
                        value={values?.date?.days?.toString() || ""}
                        onChange={(e: any) => { changeDays(Number(e?.target?.value)); }}
                        type="number"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TimePicker
                        label="Uhrzeit bis wann"
                        value={dayjs(values?.date?.dateTime || 0)}
                        onChange={(newValue: Dayjs | null) => { if (!newValue) return; changeDateTime(newValue.tz("Europe/Berlin")); }}
                    />
                </Grid>
            </>}
        </Grid>
    </Paper>
}