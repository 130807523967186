import { useState } from "react";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import AreYouSureComponent from "components/base/Dialogs/Warnings/are-you-sure";
import { SettingsListItem } from "components/common/layouts/Settings/Settings-List-Item";
import EditTicketDialog, { defaultMaxTime } from "./edit-ticket-group-dialog";
import { addTicketGroupToFirebase, deleteTicket } from "../../lib/firebase-helper";
import { GroupingTemplate } from "../../../../common/layouts/Settings/Grouping-Template/grouping-template";

export default function TicketGroups() {
    const uid = useSelector((state: any) => state.firebase.auth.uid);
    const activeSeasonRedux = useSelector((state: any) => state.firestore.ordered.activeSeason);
    const activeSeasonTicketGroupingsRedux = useSelector((state: any) => state.firestore.ordered.activeSeasonTicketsGroups);

    const [isEditTicketGroupVisible, setEditTicketGroupIsVisible] = useState(false)
    const [editTicketGroupID, setEditTicketGroupID] = useState<string | null>(null)
    const [isAreYouSureVisible, setAreYouSureIsVisible] = useState(false)

    function openEditTicketGroup(id: string) {
        setEditTicketGroupID(id)
        setEditTicketGroupIsVisible(true);
    }

    function closeEditTicketGroup() {
        setEditTicketGroupIsVisible(false);
        setEditTicketGroupID(null)
    }

    function addNewTicketGroup() {
        const newData = {
            Title: "",
            Image: "",
            Description: "",
            Tickets: [],
            Cancellation: defaultMaxTime,
            Confirmation: defaultMaxTime,
            CreatedOn: Date.now()
        }

        addTicketGroupToFirebase(newData, uid, activeSeasonRedux[0]?.id)
            .then((res) => {
                const id = res.id;
                openEditTicketGroup(id);
                toast.success("Created Ticket")
            })
            .catch((error) => {
                toast.error("Error while creating Ticket")
                console.log({ error })
            })
    }

    function closeAreYouSureFunction() {
        setAreYouSureIsVisible(false);
    }

    function openAreYouSureFunction() {
        setAreYouSureIsVisible(true);
    }

    function deleteTicketFunction() {
        if (!editTicketGroupID) return;
        deleteTicket(editTicketGroupID, uid, activeSeasonRedux[0]?.id);
        closeAreYouSureFunction();
        closeEditTicketGroup();
    }

    return <Grid container spacing={1} style={{ textAlign: "center", marginTop: '1rem' }}>
        <AreYouSureComponent
            isOpen={isAreYouSureVisible}
            title={`Do you really want to delete this Ticket?`}
            noFunction={closeAreYouSureFunction}
            yesFunction={deleteTicketFunction}
        />

        <EditTicketDialog
            visible={isEditTicketGroupVisible}
            closeFunction={closeEditTicketGroup}
            key={`Add Ticket Dialog ${editTicketGroupID}`}
            id={editTicketGroupID}
            deleteTicketFunction={openAreYouSureFunction}
        />

        <GroupingTemplate
            title={"Tickets Group"}
            addNewFunction={addNewTicketGroup}
            isAddButtonVisible
        >
            {activeSeasonTicketGroupingsRedux?.map((ticket: any) => {
                return <SettingsListItem key={`Grid ${ticket.id}`} title={ticket?.Title || ""} editFunction={() => { openEditTicketGroup(ticket?.id) }} />
            })}
        </GroupingTemplate>

    </Grid>
}
