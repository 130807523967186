import { Modal } from "@mui/material";
import { MoonLoader } from "react-spinners";

export function LoadingSpinnerModal({ loading }: { loading: boolean }) {
    return <Modal open={loading} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <MoonLoader
            loading={loading}
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
        />
    </Modal>
}