import { Grid } from "@mui/material";
import EditDaysSetting from "components/base/Calendars/Edit-Hours/edit-days";
import { PrivatePageLayout } from "components/common/layouts/Page/private";

export function EditHoursPage() {
    return <PrivatePageLayout
        link={"https://images.pexels.com/photos/16144099/pexels-photo-16144099.jpeg?auto=compress&cs=tinysrg"}
        activeLink={"calendar"}
        activeSubLink={"Edit Hours"}
        title={"Edit Dates"}
    >
        <Grid container style={{ maxWidth: "1200px", margin: 'auto' }} spacing={0}>
            <Grid item xs={12}>
                <EditDaysSetting />
            </Grid>
        </Grid>
    </PrivatePageLayout>
}