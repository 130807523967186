import { Button, Grid, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { GoogleAuthProvider, linkWithPopup, onAuthStateChanged, unlink } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { auth, db } from "utilities/Firebase/firebase-redux";

const provider = new GoogleAuthProvider();
provider.addScope("https://www.googleapis.com/auth/calendar");

export default function ConnectedAccountGoogle() {
    const uid = useSelector((state: any) => state.firebase.auth.uid);
    const connectedAccounts = useSelector((state: any) => state.firestore.ordered.connectedAccounts);

    const [isGoogleAuthProvided, setGoogleAuthIsProvided] = useState(false);
    const [calId, setCalId] = useState('')

    useEffect(()=>{
        if(!connectedAccounts || connectedAccounts?.length < 0) return;
        setCalId(connectedAccounts[0]?.GoogleCalendarId || "")
    }, [connectedAccounts])

    useEffect(()=>{
        onAuthStateChanged(auth, (user)=>{
            user?.providerData?.forEach((provider) =>{
                console.log({provider})
                if(provider.providerId === "google.com") setGoogleAuthIsProvided(true)
            })
            // user?.getIdToken().then((res)=>{
            //     console.log({res}, GoogleAuthProvider.credential(res))
            // })
        })
    }, [])

    async function addToDoc(data: any) {
        const myDoc = doc(db, `/Parks/${uid}/Connected_Accounts/accounts`)
        setDoc(myDoc, data, {merge: true})
    }

    function googleLogin() {
        if(!auth.currentUser) return;
        linkWithPopup(auth.currentUser, provider)
            .then((res)=>{
                const credential = GoogleAuthProvider.credentialFromResult(res);
                console.log({credential, res})
                addToDoc({accessToken: credential?.accessToken})
                    .then((res) => {
                        setGoogleAuthIsProvided(true);
                        toast.success("Google linked to your Account")
                    })
                    .catch((err)=>{
                        console.log({err})
                        toast.error("Error while adding your Google Account Acces Token")
                    })
            })
            .catch((err)=>{
                console.log({err})
                toast.error("Error while linking your Google Account")
            })
    }

    function googleLogout() {
        if(!auth.currentUser) return;
        unlink(auth.currentUser, provider.providerId)
            .then((res)=>{
                console.log({res})
                setGoogleAuthIsProvided(false);
                toast.success("Google Unlinked from your Account")
            })
    }

    function saveToDoc() {
        addToDoc({GoogleCalendarId: calId})
            .then((res)=>{
                console.log({res})
                toast.success("Google Calendar Id updated")
            })
            .catch((err)=>{
                console.log({err})
                toast.error("Error while updating your Google Calendar Id")
            })
    }


    return <Grid container spacing={1} style={{margin: 'auto', textAlign: 'center'}}>
        <Typography>{isGoogleAuthProvided ? "Verbunden" : "Noch nicht verbunden"}</Typography>
        {!isGoogleAuthProvided && <Grid item xs={12}>
                <Button variant="contained" fullWidth onClick={googleLogin}>Login</Button>
            </Grid>}
        {isGoogleAuthProvided && <>
            <Grid item xs={12}>
                <Typography>Click <a target="_blank" href="https://docs.simplecalendar.io/find-google-calendar-id/" rel="noreferrer">here</a> to find your Calendar Id</Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField label="Calendar Id" fullWidth onChange={(e)=>{setCalId(e.target.value)}} value={calId}/>
            </Grid>
            <Grid item xs={6}>
                <Button variant="contained" fullWidth color="error" onClick={googleLogout}>Log Out</Button>
            </Grid>
            <Grid item xs={6}>
                <Button variant="contained" fullWidth onClick={saveToDoc}>Save</Button>
            </Grid>
        </>}
    </Grid>
}