import { Grid } from "@mui/material";
import BusinessHours from "components/base/Calendars/Business-Hours";
import { PrivatePageLayout } from "components/common/layouts/Page/private";

export default function BussinessHoursPage() {
    return <PrivatePageLayout
        link={"https://images.pexels.com/photos/13929888/pexels-photo-13929888.jpeg"}
        activeLink="calendar"
        activeSubLink="business-hours"
        title={"Business Hours"}
    >
        <Grid container style={{ maxWidth: "1200px", margin: 'auto' }} spacing={0}>
            <Grid item xs={12}>
                <BusinessHours />
            </Grid>
        </Grid>
    </PrivatePageLayout>
}