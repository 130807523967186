import { PublicPageLayout } from "components/common/layouts/Page/public";
import {LoginPaperComponent} from "components/base/Login"
import { Grid } from "@mui/material";

export function LoginPage() {
    return <PublicPageLayout link={"https://images.pexels.com/photos/3225517/pexels-photo-3225517.jpeg?auto=compress&cs=tinysrgb"}>
        <Grid container spacing={1}>
            <Grid item xs={12} style={{marginTop: "3rem"}}>
                <LoginPaperComponent />
            </Grid>
        </Grid>
    </PublicPageLayout>
}