import { useEffect, useState } from "react";

export default function CurrentlyOpen({ businessHours }:
    { businessHours: any, }) {

    const [now, setNow] = useState<number>(Date.now())
    const [timeText, setTimeText] = useState<any>("Einen Moment bitte, die Homepage benötigt einige Sekunden, um die aktuellen Öffnungszeiten anzuzeigen.")

    useEffect(() => {
        if (!businessHours) return;

        var timings: string = "Wir haben heute geschlossen";
        businessHours?.TimeTexts?.forEach((timeText: any) => {
            if (now >= timeText?.Start?.DateTime && now <= timeText?.End?.DateTime) {
                timings = timeText?.Text
            }
        })

        setTimeText(timings);
    }, [businessHours, now])

    setInterval(() => {
        setNow(Date.now())
    }, 5000)

    return <span style={{ margin: 'auto' }}>
        {timeText}
    </span>
}