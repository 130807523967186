import { Button, Grid, TextField } from "@mui/material"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "utilities/Firebase/firebase-redux";
import AccordionCustomComponent from "components/common/layouts/Accordion";
import CountryDropdown from "components/common/ui/Selects/Country-Select/country-select";

export default function ParkLocation() {
    const park = useSelector((state: any) => state.firestore.ordered.park);
    const uid = useSelector((state: any) => state.firebase.auth.uid);
    
    const [country, setCountry] = useState('')
    const [postCode, setPostCode] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [line1, setLine1] = useState('')
    const [line2, setLine2] = useState('')
   
    useEffect(()=>{
        if(!park) return;
        setCountry(park[0]?.Country || "")
        setPostCode(park[0]?.PostCode || "")
        setCity(park[0]?.City || "")
        setState(park[0]?.State || "")
        setLine1(park[0]?.Line1 || "")
        setLine2(park[0]?.Line2 || "")
    }, [park])

    function submitHandler(event: any) {
        event.preventDefault();
        if(!uid) {
            toast.error("Not logged in");
            return;
        }
        const myDoc = doc(db, `/Parks/${uid}`)
        const newData = {
            PostCode: postCode,
            City: city,
            State: state,
            Line1: line1,
            Line2: line2,
            Country: country,
        }
        updateDoc(myDoc, newData)
            .then(()=>{
                toast.success("Updated Location Information")
            })
            .catch((err)=>{
                toast.error("There was an error while Updating your Location Information")
            })
    }

    return <AccordionCustomComponent title={"Park Location"}>
        <form onSubmit={submitHandler}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    {/* <Typography>Select Country</Typography> */}
                    <CountryDropdown required key={`Country Dropdown ${country}`} setCountry={setCountry} defaultCountry={country} />
                </Grid>
                <Grid item xs={6}>
                    <TextField size="small" required value={state} onChange={(e)=>{setState(e?.target?.value)}} fullWidth type={'text'} label={"State"}/>
                </Grid>
                <Grid item xs={6}>
                    <TextField size="small" required value={postCode} onChange={(e)=>{setPostCode(e?.target?.value)}} fullWidth type={'text'} label={"Post Code"}/>
                </Grid>
                <Grid item xs={12}>
                    <TextField size="small" required value={city} onChange={(e)=>{setCity(e?.target?.value)}} fullWidth type={'text'} label={"City"}/>
                </Grid>
                <Grid item xs={12}>
                    <TextField size="small" required value={line1} onChange={(e)=>{setLine1(e?.target?.value)}} fullWidth type={'text'} label={"Line 1"}/>
                </Grid>
                <Grid item xs={12}>
                    <TextField size="small" value={line2} onChange={(e)=>{setLine2(e?.target?.value)}} fullWidth type={'text'} label={"Line 2"}/>
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" fullWidth type="submit">Update</Button>
                </Grid>
            </Grid>
        </form>
    </AccordionCustomComponent>
}