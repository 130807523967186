import { Checkbox, Grid, TextField, TextareaAutosize, Typography } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { FormDialogLayout } from "components/common/layouts/Dialog/form-dialog-layout";
import { useFormik } from "formik";
import { updatePriceDoc } from "../lib/firebase-helper";

type PriceType = {
	price: number,
	title: string,
	description: string,
}

export default function EditPriceDialog(
	{ visible, closeFunction, id, deleteTicketFunction }:
		{ visible: boolean, closeFunction: any, id: string | null, deleteTicketFunction: any }
) {
	const uid = useSelector((state: any) => state.firebase.auth.uid);
	const activeSeasonRedux = useSelector((state: any) => state.firestore.ordered.activeSeason);
	const activeSeasonPricesRedux = useSelector((state: any) => state.firestore.ordered.activeSeasonPrices);

	const [isLoading, setIsLoading] = useState(false);

	const formik = useFormik<PriceType>({
		initialValues: {
			price: 0,
			title: "",
			description: ""
		},
		// validationSchema: PersonalInformationSchema,
		onSubmit: updatePrice,
	});

	useEffect(() => {
		if (!id) return;
		activeSeasonPricesRedux?.forEach((ticketData: any) => {
			if (ticketData.id !== id) return;
			formik.setFieldValue("title", ticketData?.Title || "")
			formik.setFieldValue("price", ticketData?.Price || 0)
			formik.setFieldValue("description", ticketData?.Description || "")
		})
	}, [activeSeasonPricesRedux, id])

	function updatePrice(values: PriceType) {
		if (!id) return;
		setIsLoading(true)

		const newData = {
			Title: values.title,
			Price: values.price,
			Description: values.description,
			UpdatedOn: Date.now()
		}

		updatePriceDoc(id, newData, uid, activeSeasonRedux[0]?.id)
			.then(() => {
				setIsLoading(false);
				toast.success("Updated Price")
				closeFunction()
			})
			.catch((error) => {
				setIsLoading(false);
				toast.error("Error while updating Price")
				console.log({ error })
			})
	}

	return <FormDialogLayout
		isOpen={visible}
		title={"Edit Price"}
		closeFunction={closeFunction}
		deletFunction={deleteTicketFunction}
		submitFunction={formik.handleSubmit}
		loading={isLoading}
	>
		<Grid container spacing={1}>
			<Grid item xs={12} style={{ display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
				<TextField
					fullWidth
					error={formik.touched.title && Boolean(formik.errors.title)}
					label={"Title"}
					placeholder={"Name this Ticket"}
					size="small"
					value={formik.values.title}
					onChange={formik.handleChange}
					name="title"
					required
				/>
			</Grid>
			<Grid item xs={12}>
				<TextareaAutosize
					style={{ width: "100%" }}
					minRows={5}
					placeholder="Description"
					name="description"
					value={formik.values.description}
					onChange={formik.handleChange}
				/>
			</Grid>
			<Grid item xs={12} style={{ display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
				<TextField
					fullWidth
					error={formik.touched.price && Boolean(formik.errors.price)}
					label={"Price"}
					placeholder={"Price"}
					type="number"
					size="small"
					value={formik.values.price}
					onChange={formik.handleChange}
					name="price"
				/>
			</Grid>
		</Grid>
	</FormDialogLayout>
}