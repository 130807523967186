import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { db } from "utilities/Firebase/firebase-redux";
import { DateCalendar, DayCalendarSkeleton, PickersDay, PickersDayProps } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { Badge } from "@mui/material";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";

export async function getMyPark(parkId: string) {
    const ref = doc(db, `/Parks/${parkId}`);
    return await getDoc(ref);
}

export async function getBusinessHourDataFromPark(parkId: string) {
    const ref = collection(db, `/Parks/${parkId}/Business-Hours`);
    return await getDocs(ref);
}

export async function getActiveSeason(parkId: string, currentSeason: string) {
    const ref = doc(db, `/Parks/${parkId}/Seasons/${currentSeason}`);
    return await getDoc(ref);
}

export function IFrameCalendarPage() {

    const [activeSeason, setActiveSeason] = useState<any>({})
    const [businessHours, setBusinessHours] = useState<any[]>([])
    const [parkData, setParkData] = useState<any>({})
    const [parkId, setParkId] = useState<string | undefined>("");
    const [startDate, setStartDate] = useState(dayjs());
    const [endDate, setEndDate] = useState(dayjs());

    const params = useParams()

    useEffect(() => {
        const parkId = params?.parkid;
        setParkId(parkId)
    }, [params])

    useEffect(() => {
        if (!parkId) return;
        getBusinessHourDataFromPark(parkId)
            .then((res) => {
                const arr = res.docs.map((d) => {
                    const data = d.data();
                    data.id = d.id;
                    return data;
                })
                setBusinessHours(arr);
            })
            .catch((error) => {
                console.error({ error })
            })
    }, [parkId])

    useEffect(() => {
        if (!activeSeason?.StartDateTime || !activeSeason?.EndDateTime) return;

        setStartDate(dayjs(activeSeason?.StartDateTime || 0))
        setEndDate(dayjs(activeSeason?.EndDateTime || 0))
    }, [activeSeason])

    useEffect(() => {
        if (!parkId || !parkData || !parkData?.ActiveSeason) return;
        getActiveSeason(parkId, parkData?.ActiveSeason)
            .then((res) => {
                const data: any = res.data();
                data.id = res.id;
                setActiveSeason(data);
            })
            .catch((error) => {
                console.error({ error })
            })
    }, [parkId, parkData])

    useEffect(() => {
        if (!parkId) return;
        getMyPark(parkId)
            .then((res) => {
                const data: any = res.data();
                data.id = res.id;
                setParkData(data);
            })
            .catch((error) => {
                console.error({ error })
            })
    }, [parkId])

    function doesDayHaveAnEvent(businessHoursArray: any[], dateTime: number) {
        let returnVal = false;
        businessHoursArray?.forEach((bHour) => {
            if (bHour?.Start?.DateTime === dateTime) returnVal = true;
        })
        return returnVal
    }

    function dateClick(date: Dayjs | null) {
        if (!date) return;
        const startOfDayDateTime = date.tz("Europe/Berlin").set('hours', 0).set('minutes', 0).set('seconds', 0).set('milliseconds', 0).toDate().getTime()

        if (!doesDayHaveAnEvent(businessHours, startOfDayDateTime)) {
            const link = `https://tickets.touritickets.de/event-process/${parkId}`
            window?.open(link, '_blank')?.focus();
        } else {
            // const date = new Date(startOfDayDateTime);
            const link = `https://tickets.touritickets.de/event-process/${parkId}`
            window?.open(link, '_blank')?.focus();
        }
    }

    return <div style={{ margin: 'auto' }}>
        <DateCalendar
            minDate={startDate}
            maxDate={endDate}
            value={dayjs()}
            showDaysOutsideCurrentMonth
            onChange={dateClick}
            renderLoading={() => <DayCalendarSkeleton />}
            slots={{
                day: ServerDay,
            }}
            slotProps={{
                day: {
                    highlighted: businessHours
                } as any,
            }}
        />
    </div>
}


interface PickersDayPropsExtended extends PickersDayProps<Dayjs> {
    highlighted?: any[],
}

function getDayIndex(businessHoursArray: any[], day: Dayjs): number {
    let returnVal = -1;
    businessHoursArray?.forEach((businessHour, index: number) => {
        if (businessHour?.Start?.DateTime !== day?.toDate()?.getTime()) return;
        returnVal = index;
    })
    return returnVal;
}

function ServerDay(props: PickersDayPropsExtended) {
    const { highlighted = [], day, outsideCurrentMonth, today, ...other } = props;

    const date = day.toDate();
    const dateTime = date.getTime();

    // let color = "";
    // highlighted.forEach((businessHour) => {
    //     const businessHourDateTime = businessHour?.Start?.DateTime;
    //     if (businessHourDateTime !== dateTime) return;
    //     color = businessHour?.Color;
    // })

    const isDateSelected: boolean = dateTime === day.toDate().getTime();

    const index: number = getDayIndex(highlighted, day);

    const color = highlighted[index]?.Color || "white";

    const isDayPast: boolean = day.toDate().getTime() <= dayjs().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).toDate().getTime()


    const style = {
        border: today ? "1px solid black" : isDateSelected ? `1px solid ${color}` : "none",
        backgroundColor: color, //isDateSelected ? "#1565c0" : color,
        color: today ? "black" : isDayPast ? "grey" : 'black' // isDateSelected ? index > 0 ? "white" : "black" : today ? "white" : isDayPast ? "grey" : "black"
    }

    return (
        <Badge
            key={props.day.toString()}
            overlap="circular"
        >
            <PickersDay
                style={style}
                outsideCurrentMonth={outsideCurrentMonth}
                day={day}
                {...other}
            />
        </Badge>
    );
}