import { Button, Grid, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { db } from "utilities/Firebase/firebase-redux";
import AccordionCustomComponent from "components/common/layouts/Accordion";
import CountryDropdown from "components/common/ui/Selects/Country-Select/country-select";

export default function BusinessInformation() {
    const uid = useSelector((state: any) => state.firebase.auth.uid);
    const stripeConnect = useSelector((state: any) => state.firestore.ordered.stripeConnect);
    
    const [companyName, setCompanyName] = useState<string>('')
    const [postCode, setPostCode] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [line1, setLine1] = useState('')
    const [line2, setLine2] = useState('')
    const [country, setCountry] = useState('')
    // const [payoutSchedule, setPayoutSchedule] = useState('daily')


    function saveAndNext(event: any) {
        event.preventDefault();
        if(!uid) {
            toast.error("Not logged in");
            return;
        }
        const myDoc = doc(db, `/Parks/${uid}/stripe_connect/account`)
        const newData = {
            Name: companyName,
            Country: country,
            PostCode: postCode,
            City: city,
            State: state,
            Line1: line1,
            Line2: line2
        }
        updateDoc(myDoc, {Company: newData})
            .then(()=>{
                toast.success("Updated Business Information")
            })
            .catch((err)=>{
                toast.error("There was an error while Updating your Business Information")
            })

        // const createStripeConnectCloudFunction = httpsCallable(functions, "onUpdateBusinessDetails");
        // createStripeConnectCloudFunction({
        //     Name: companyName,
        //     PostCode: postCode,
        //     City: city,
        //     State: state,
        //     Line1: line1,
        //     Line2: line2,
        //     Country: country,
        //     PayoutSchedule: payoutSchedule
        // })
        //     .then((res)=>{
        //         toast.success("Updated Bank Details")
        //     })
        //     .catch((err)=>{
        //         console.log({err})
        //         toast.error("There was an error while Updating your Bank Details")                
        //     })
    }

    useEffect(()=>{
        if(!stripeConnect) return;
        setCompanyName(stripeConnect[0]?.Company?.Name || "")
        setCountry(stripeConnect[0]?.Company?.Country || "")
        setPostCode(stripeConnect[0]?.Company?.PostCode || "")
        setCity(stripeConnect[0]?.Company?.City || "")
        setState(stripeConnect[0]?.Company?.State || "")
        setLine1(stripeConnect[0]?.Company?.Line1 || "")
        setLine2(stripeConnect[0]?.Company?.Line2 || "")
        // setPayoutSchedule(stripeConnect[0]?.Company?.PayoutSchedule || "daily")
    }, [stripeConnect])

    return <AccordionCustomComponent title={"Business Details"}>
        <form onSubmit={saveAndNext}>
            <Grid container spacing={1}>
                {/* <Grid item xs={12}>
                    <Typography style={{textAlign: 'center'}} typography={"h2"}>Business Information</Typography>
                    <Typography>Location where the company of your park is registered</Typography>
                </Grid> */}
                {/* <Grid item xs={12}>
                    Company Type Select: Business // non-profit // individual
                </Grid>
                <Grid item xs={12}>
                    Company Cetegory Select: Tourism, Software, ...
                </Grid> */}
                {/* <Grid item xs={12}>
                    <Typography>Payout Schedule</Typography>
                    <PayoutSchedule key={`Payout Schedule ${payoutSchedule}`} defaultValue={payoutSchedule} setPayoutSchedule={setPayoutSchedule} />
                </Grid> */}
                <Grid item xs={12}>
                    <TextField required value={companyName} onChange={(e)=>{setCompanyName(e?.target?.value)}} fullWidth type={'text'} label={"Company Name"}/>
                </Grid>
                <Grid item xs={12}>
                    <Typography>Select Country</Typography>
                    <CountryDropdown required key={`Country Dropdown ${country}`} setCountry={setCountry} defaultCountry={country} />
                </Grid>
                <Grid item xs={6}>
                    <TextField required value={state} onChange={(e)=>{setState(e?.target?.value)}} fullWidth type={'text'} label={"State"}/>
                </Grid>
                <Grid item xs={6}>
                    <TextField required value={postCode} onChange={(e)=>{setPostCode(e?.target?.value)}} fullWidth type={'text'} label={"Post Code"}/>
                </Grid>
                <Grid item xs={12}>
                    <TextField required value={city} onChange={(e)=>{setCity(e?.target?.value)}} fullWidth type={'text'} label={"City"}/>
                </Grid>
                <Grid item xs={12}>
                    <TextField required value={line1} onChange={(e)=>{setLine1(e?.target?.value)}} fullWidth type={'text'} label={"Address Line 1"}/>
                </Grid>
                <Grid item xs={12}>
                    <TextField value={line2} onChange={(e)=>{setLine2(e?.target?.value)}} fullWidth type={'text'} label={"Address Line 2"}/>
                </Grid>

                <Grid item xs={12}>
                    <Button fullWidth variant="contained" type="submit">Update</Button>
                </Grid>
            </Grid>
        </form>
    </AccordionCustomComponent>
}