import { Grid } from "@mui/material";
import { PrivatePageLayout } from "components/common/layouts/Page/private";

export function GroupingsPage() {
    return <PrivatePageLayout
        link={"https://images.pexels.com/photos/1894350/pexels-photo-1894350.jpeg"}
        activeLink={"tickets,prices,times"}
        activeSubLink={"Groupings"}
        title={"Gruppierungen"}
    >
        <Grid container style={{ maxWidth: "1000px", margin: 'auto' }} spacing={0}>
            {/* <Grid item xs={12}>
                <TicketPriceGrouping />
            </Grid> */}
            {/* <Grid item xs={12}>
                <TicketTimeGrouping />
            </Grid> */}
        </Grid>
    </PrivatePageLayout>
}