import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { LoadingSpinnerModal } from "../Loading-Spinner/loading-spinner-modal";

export function FormDialogLayout({ isOpen, title, closeFunction, deletFunction, submitFunction, children, loading = false, deleteIsDisabled = false, submitButtonText = "Update" }:
	{ isOpen: boolean, title: string, closeFunction: any, deletFunction?: any, submitFunction: any, children?: any, loading?: boolean, deleteIsDisabled?: boolean, submitButtonText?: string }) {

	return <Dialog open={isOpen}>
		<LoadingSpinnerModal loading={loading} />
		<form onSubmit={submitFunction}>
			<DialogTitle variant="h2" style={{ display: 'flex', justifyContent: 'space-between', padding: '0.5rem' }}>
				{title}
				<IconButton onClick={closeFunction}>
					<CloseOutlined />
				</IconButton>
			</DialogTitle>
			<DialogContent style={{ padding: "1rem" }}>
				{children}
			</DialogContent>
			<DialogActions>
				<div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
					{!deleteIsDisabled && <Button color="error" type="button" variant="contained" onClick={deletFunction} disabled={loading}>Delete</Button>}
					<Button color="success" variant="contained" type="submit" disabled={loading} fullWidth={deleteIsDisabled}>{submitButtonText}</Button>
				</div>
			</DialogActions>
		</form>
	</Dialog>
}