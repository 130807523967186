import { MenuItem, Select } from "@mui/material";
import { useState } from "react";
import LANGUAGES from "data/languages.json"

export default function LanguageDropdown({setLanguage, defaultLanguage = ""}: {setLanguage: any; defaultLanguage?: string}) {
    const [select, setSelect] = useState(defaultLanguage)

    function onChange(e: any) {
        setSelect(e.target.value);
        setLanguage(e.target.value);
    }

    return <Select fullWidth value={select} onChange={onChange}>
        {LANGUAGES?.map((language)=>{
            return <MenuItem disabled={!(language?.code==="de" || language?.code==="en")} value={language?.code} key={`Language ${language?.code}`}>{language?.nativeName}</MenuItem>
        })}
    </Select>;
}