import { EventCalendar } from "components/base/Calendars/Event-Calendar";
import { PrivatePageLayout } from "components/common/layouts/Page/private";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export function EventCalendarPage() {
    const [searchParams] = useSearchParams();
    const [reservationId, setReservationId] = useState<string>();
    const [bookingId, setBookingId] = useState<string>();
    const [isBooking, setIsBooking] = useState(false);

    useEffect(() => {
        const reservationIdParam = searchParams.get("reservationId");
        setReservationId(reservationIdParam || "");

        const bookingIdParam = searchParams.get("bookingId");
        setBookingId(bookingIdParam || "");

        if (!bookingIdParam) return;
        setIsBooking(true);
    }, [searchParams])

    return <PrivatePageLayout
        link={"https://images.pexels.com/photos/13929888/pexels-photo-13929888.jpeg"}
        activeLink="calendar"
        activeSubLink="events-calendar"
        title="Events Kalender"
    >
        <EventCalendar reservationId={reservationId} isBooking={isBooking} bookingId={bookingId} />
    </PrivatePageLayout>
}