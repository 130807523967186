import { MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export function EmailTemplateSelect({defaultValue = "", setTemplate, disabled = false}: 
    {defaultValue?: string, setTemplate: any, disabled?: boolean}) {
    const emailTemplates = useSelector((state: any) => state.firestore.ordered.emailTemplates);

    const [select, setSelect] = useState(defaultValue)

    function onChange(e: any) {
        setSelect(e?.target?.value)
        setTemplate(e?.target?.value);
    }

    return <Select disabled={disabled} fullWidth value={select} onChange={onChange}>
        <MenuItem key={"Email Template None"} value={""}>None</MenuItem>
        {emailTemplates?.map((email: any)=>{
            return <MenuItem value={email?.id} key={`Email Template ${email?.id}`}>{email?.title}</MenuItem>
        })}
    </Select>
}