import { Grid } from "@mui/material";
import TicketGroups from "components/base/Hours-And-Tickets/Tickets/ticketGroup/ticketGroups";
import Tickets from "components/base/Hours-And-Tickets/Tickets/tickets/tickets";
import { PrivatePageLayout } from "components/common/layouts/Page/private";

export function TicketsPage() {
    return <PrivatePageLayout
        link={"https://images.pexels.com/photos/1834407/pexels-photo-1834407.jpeg?auto=compress&cs=tinysrgb"}
        activeLink={"tickets,prices,times"}
        activeSubLink={"tickets"}
        title="Tickets"
    >
        <Grid container style={{ maxWidth: "1000px", margin: 'auto' }} spacing={0}>
            <Grid item xs={12}>
                <Tickets />
            </Grid>
            <Grid item xs={12}>
                <TicketGroups />
            </Grid>
        </Grid>
    </PrivatePageLayout>
}