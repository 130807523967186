import dayjs, { Dayjs } from "dayjs";
import { getLocaleDateString } from "../Business-Hours/lib/date-helper";
import { Button, Divider, Grid, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { FormDialogLayout } from "components/common/layouts/Dialog/form-dialog-layout";
import { useEffect, useState } from "react";
import { MuiColorInput } from "mui-color-input";
import { TimeTicketGroupingItem } from "components/base/Hours-And-Tickets/Times/timeGroup/time-ticket-grouping-item";
import { BusinessHoursTimeText } from "components/base/Hours-And-Tickets/Times/timeGroup/business-hours-time-text";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { addBusinessHourFirebase, addOldBusinessHourFirebase, getOldBusinessDayData } from "../Business-Hours/lib/firebase-helper";
import { BusinessHourSelect } from "../Business-Hours/business-hour-select";
import { SettingsListItem } from "components/common/layouts/Settings/Settings-List-Item";
import { GroupingTemplate } from "components/common/layouts/Settings/Grouping-Template";
import { ShowBusinessDayDialog } from "./show-business-day-dialog";
import { TicketGroupFirebaseObject, TimeTextFirebaseObject, findIndexOfExistingDateTimeInArray, getBusinessHour, getTicketGroups, getTimeText, getTimeTexts } from "../Business-Hours/lib/business-hour-helper";

export function EditBusinessDayDialog({ visible, date, closeFunction, activeSeason }:
    { visible: boolean, date?: Dayjs, closeFunction: any; activeSeason: string }) {
    const activeSeasonBusinessHoursRedux = useSelector((state: any) => state.firestore.ordered.activeSeasonBusinessHours);
    const activeSeasonPricesRedux = useSelector((state: any) => state.firestore.ordered.activeSeasonPrices);
    const activeSeasonTicketGroupingsRedux = useSelector((state: any) => state.firestore.ordered.activeSeasonTicketsGroups);
    const activeSeasonTimingGroupsRedux = useSelector((state: any) => state.firestore.ordered.activeSeasonTimingGroups);
    const activeSeasonTicketsRedux = useSelector((state: any) => state.firestore.ordered.activeSeasonTickets);

    const uid = useSelector((state: any) => state.firebase.auth.uid);

    const [isLoading, setIsLoading] = useState(false);
    const [businessDay, setBusinessDay] = useState<any>();
    const [calendarSelectedBusinessHourId, setCalendarSelectedBusinessHourId] = useState("")
    const [businessHoursTextsKey, setBusinessHoursTextsKey] = useState(0);
    const [oldBusinessDayId, setOldBusinessDayId] = useState("")
    const [activeSeasonOldBusinessHours, setActiveSeasonOldBusinessHours] = useState<any[]>([])

    useEffect(() => {
        if (!activeSeason || !date || !uid) return;
        const localeDateString = getLocaleDateString(date?.toDate()?.getTime() || 0)

        getOldBusinessDayData(uid, localeDateString)
            .then((res) => {
                const docs = res.docs.map((doc) => {
                    const data = doc.data();
                    data.id = doc.id;
                    return data;
                })
                setActiveSeasonOldBusinessHours(docs);
            })
            .catch((err) => {
                console.log({ err })
            })
    }, [activeSeason, uid])

    function getTicketTimeIndex(ticketEvent: any, ticketTimes: any[]) {
        var returnVal = -1;
        ticketTimes?.forEach((ticketTime, index) => {
            if (ticketTime?.startTime === ticketEvent?.start?.dateTime &&
                ticketTime?.endTime === ticketEvent?.end?.dateTime) returnVal = index;
        })
        return returnVal;
    }

    // function getTimeText(timeTextData: any, startOfDayDateTime: number) {
    //     const startTime = calculateDayJsDateOfTwoDates(dayjs(startOfDayDateTime), dayjs(timeTextData?.startTime));
    //     const endTime = calculateDayJsDateOfTwoDates(dayjs(startOfDayDateTime), dayjs(timeTextData?.endTime));
    //     const newObj = {
    //         Text: timeTextData?.text,
    //         Start: {
    //             DateTime: startTime.toDate().getTime(),
    //             Timezone: "Europe/Berlin"
    //         },
    //         End: {
    //             DateTime: endTime.toDate().getTime(),
    //             Timezone: "Europe/Berlin"
    //         },
    //     }
    //     return newObj;
    // }

    useEffect(() => {
        if (!activeSeasonBusinessHoursRedux) return;

        const myDoc: any = findIndexOfExistingDateTimeInArray(getLocaleDateString(date?.toDate()?.getTime() || 0), activeSeasonBusinessHoursRedux)
        if (!myDoc) return;

        setBusinessDay(myDoc)

        // get unique starttime and endtime from all tickets 
        const ticketTimes: any[] = [];
        myDoc?.TicketGroups?.forEach((ticketGroup: any) => {
            ticketGroup?.TicketEventGroups?.forEach((ticketEventGroup: any) => {
                ticketEventGroup?.TicketEvents?.forEach((ticketEvent: any) => {
                    if (getTicketTimeIndex(ticketEvent, ticketTimes) >= 0) return;
                    ticketTimes.push({ startTime: ticketEvent?.start?.dateTime, endTime: ticketEvent?.end?.dateTime, ticketGroupIds: [] })
                })
            })
        })

        myDoc?.TicketGroups?.forEach((ticketGroup: any) => {
            ticketGroup?.TicketEventGroups?.forEach((ticketEventGroup: any) => {
                ticketEventGroup?.TicketEvents?.forEach((ticketEvent: any) => {
                    const index = getTicketTimeIndex(ticketEvent, ticketTimes);
                    const newTicketGroupId = ticketTimes[index]?.ticketGroupIds;
                    if (newTicketGroupId?.includes(ticketGroup?.TicketGroupId)) return;
                    newTicketGroupId.push(ticketGroup?.TicketGroupId);
                    ticketTimes[index].ticketGroupIds = newTicketGroupId;
                })
            })
        })

        const timeTexts: any[] = myDoc?.TimeTexts?.map((timeText: any) => {
            return {
                startTime: timeText?.Start?.DateTime,
                endTime: timeText?.End?.DateTime,
                text: timeText?.Text
            }
        })

        formik.setFieldValue("timings", structuredClone(ticketTimes) || [])
        formik.setFieldValue("businessHoursTimeTexts", structuredClone(timeTexts) || [])
        formik.setFieldValue("color", myDoc?.Color || "")
    }, [activeSeasonBusinessHoursRedux])

    const formik = useFormik<any>({
        initialValues: {
            timings: [],
            color: "",
            businessHoursTimeTexts: []
        },
        // validationSchema: PersonalInformationSchema,
        onSubmit: updateTiming,
    });


    function updateTiming(values: any) {
        if (!businessDay?.id || !uid || !date) return;
        setIsLoading(true);

        const myDoc: any = findIndexOfExistingDateTimeInArray(getLocaleDateString(date?.toDate()?.getTime() || 0), activeSeasonBusinessHoursRedux)

        const oldData = {
            CreatedOn: Date.now(),
            ...myDoc
        }

        addOldBusinessHourFirebase(oldData, uid, businessDay?.id)
            .then((res) => {
                toast.success("Successfully saved old business day")

                const startOfDayDateTime = date.tz("Europe/Berlin").set('hours', 0).set('minutes', 0).set('seconds', 0).set('milliseconds', 0).toDate().getTime()

                const selectedBusinessHour: any = getBusinessHour(myDoc?.BusinessHourId, activeSeasonTimingGroupsRedux);

                const businessHour = {
                    Timings: values.timings,
                    Cancallation: selectedBusinessHour?.Cancallation || 0,
                    Confirmation: selectedBusinessHour?.Confirmation || 0,
                }

                const ticketGroups: TicketGroupFirebaseObject[] = getTicketGroups(businessHour, startOfDayDateTime, activeSeasonTicketGroupingsRedux, activeSeasonTicketsRedux, activeSeasonPricesRedux)

                const newTimeTextArray: TimeTextFirebaseObject[] = values.businessHoursTimeTexts?.map((timeText: any) => {
                    return getTimeText(timeText, startOfDayDateTime);
                })

                const newData = {
                    TicketGroups: ticketGroups,
                    Color: values.color,
                    TimeTexts: newTimeTextArray,
                    UpdatedOn: Date.now(),
                    Season: activeSeason
                }

                addBusinessHourFirebase(newData, uid, businessDay?.id)
                    .then((res2) => {
                        closeFunction();
                        setIsLoading(false);
                        toast.success("Successfully updated business day")
                    })
                    .catch((err) => {
                        console.log({ err })
                        setIsLoading(false);
                        toast.error("There was an error updating the bussiness day")
                    })
            })
            .catch((err) => {
                console.log({ err })
                setIsLoading(false);
                toast.error("There was an error updating the bussiness day")
            })
    }

    function closeFullDay() {
        formik.setFieldValue("businessHoursTimeTexts", [])
        formik.setFieldValue("timings", [])
        formik.setFieldValue("color", "#FFFFFF")
    }

    function deleteFunction() {
        toast.error("You cannot delete a Business Day in an active season")
    }






    function addNewBusinessHourTimeText() {
        const newArr = structuredClone(formik.values.businessHoursTimeTexts);
        newArr.push({
            startTime: 0,
            endTime: 0,
            text: ''
        });
        formik.setFieldValue("businessHoursTimeTexts", structuredClone(newArr))
        setBusinessHoursTextsKey(businessHoursTextsKey + 1)
    }

    function handleFormikChangeInBusinessHoursTimeTexts(timingIndex: number, field: string, value: number | string) {
        const newArr = structuredClone(formik.values.businessHoursTimeTexts);
        const nowObj: any = newArr[timingIndex];
        nowObj[field] = value;
        formik.setFieldValue("businessHoursTimeTexts", structuredClone(newArr))
    }

    function deleteBusinessHourTimeText(index: number) {
        const newArr: any[] = structuredClone(formik.values.businessHoursTimeTexts);
        newArr?.splice(index, 1);
        formik.setFieldValue("businessHoursTimeTexts", structuredClone(newArr))
        setBusinessHoursTextsKey(businessHoursTextsKey + 1)
    }





    function addNewTicketTimeGrouping() {
        const newArr = structuredClone(formik.values.timings);
        newArr.push({
            startTime: 0,
            endTime: 0,
            ticketGroupIds: []
        });
        formik.setFieldValue("timings", structuredClone(newArr))
    }

    function handleFormikChangeInTimings(timingIndex: number, field: string, value: number) {
        const newArr = structuredClone(formik.values.timings);
        const nowObj: any = newArr[timingIndex];
        nowObj[field] = value;
        formik.setFieldValue("timings", structuredClone(newArr))
    }

    function deleteTicketTimeGrouping(index: number) {
        const newArr: any[] = structuredClone(formik.values.timings);
        newArr?.splice(index, 1);
        formik.setFieldValue("timings", structuredClone(newArr))
    }

    function initializeNewBusinessDay() {
        if (!calendarSelectedBusinessHourId || !date) return;
        setIsLoading(true);

        const startOfDayDateTime = date.tz("Europe/Berlin").set('hours', 0).set('minutes', 0).set('seconds', 0).set('milliseconds', 0).toDate().getTime()
        const endOfDayDateTime = date.tz("Europe/Berlin").set('hours', 23).set('minutes', 59).set('seconds', 59).set('milliseconds', 999).toDate().getTime()

        const selectedBusinessHour: any = getBusinessHour(calendarSelectedBusinessHourId, activeSeasonTimingGroupsRedux);

        const newData = {
            Creation: "In-Edit-Mode",
            Start: {
                DateTime: startOfDayDateTime,
                Timezone: "Europe/Berlin"
            },
            End: {
                DateTime: endOfDayDateTime,
                Timezone: "Europe/Berlin"
            },
            Title: selectedBusinessHour?.Title || "",
            Description: selectedBusinessHour?.Description || "",
            Color: selectedBusinessHour?.Color || "",
            BusinessHourId: selectedBusinessHour?.id || "",
            Season: activeSeason,
        }
        const localeDateString = getLocaleDateString(date?.toDate()?.getTime() || 0)
        addBusinessHourFirebase(newData, uid, localeDateString)
            .then((res) => {
                setIsLoading(false);
                toast.success(("Created new Day"))
            })
            .catch((err) => {
                console.log({ err })
                toast.error("Error while creating a new day")
            })
    }

    function loadOldBusienssDayData(data: any) {
        formik.setFieldValue("timings", structuredClone(data?.timings) || [])
        formik.setFieldValue("businessHoursTimeTexts", structuredClone(data?.businessHoursTimeTexts) || [])
        formik.setFieldValue("color", data?.color || "")
        closeOldBusinessDayDialog()
    }

    function closeOldBusinessDayDialog() {
        setOldBusinessDayId("")
    }

    return <FormDialogLayout
        isOpen={visible}
        title={`Edit ${getLocaleDateString(date?.toDate()?.getTime() || 0)}`}
        closeFunction={closeFunction}
        deletFunction={deleteFunction}
        submitFunction={formik.handleSubmit}
        deleteIsDisabled
        loading={isLoading}
    >
        <ShowBusinessDayDialog
            key={`Show Old Business Day Dialog ${oldBusinessDayId}`}
            visible={oldBusinessDayId === "" ? false : true}
            id={oldBusinessDayId}
            setOldData={loadOldBusienssDayData}
            closeFunction={closeOldBusinessDayDialog}
            businessHoursArray={activeSeasonOldBusinessHours}
        />
        {
            !businessDay?.id && <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography>Der Tag hat keine vorhandenen Öffnungszeiten</Typography>
                </Grid>
                <Grid item xs={12}>
                    <BusinessHourSelect
                        setBusinessHour={setCalendarSelectedBusinessHourId}
                    />
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <Button onClick={initializeNewBusinessDay} disabled={!calendarSelectedBusinessHourId}>Tag initialisieren</Button>
                </Grid>
            </Grid>
        }
        {
            businessDay?.id && <Grid container spacing={1}>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <Button onClick={closeFullDay} color="error">Close full day</Button>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <GroupingTemplate
                        title={"Alle Änderungen"}
                    >
                        {activeSeasonOldBusinessHours?.map((businessHour: any) => {
                            return <SettingsListItem
                                key={`Old Business Hour ${businessHour.id}`}
                                title={`${getLocaleDateString(businessHour?.CreatedOn || 0)} - ${new Date(businessHour?.CreatedOn || 0)?.toLocaleTimeString("de")}`}
                                editFunction={() => { setOldBusinessDayId(businessHour?.id) }}
                                buttonText="Show"
                            />
                        })}
                    </GroupingTemplate>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
                    <MuiColorInput
                        required
                        fullWidth
                        error={formik.touched.color && Boolean(formik.errors.color)}
                        size="small"
                        label="Farbe"
                        name="color"
                        value={formik.values.color}
                        onChange={(color: string) => { formik.setFieldValue("color", color) }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                            <Button onClick={addNewTicketTimeGrouping}>Add new Ticket-Time Grouping</Button>
                        </Grid>
                        {formik.values.timings?.map((ticketTimeGroup: any, index: number) => {
                            return <TimeTicketGroupingItem
                                key={`Time Ticket ${index} ${ticketTimeGroup?.startTime} ${ticketTimeGroup?.endTime}`}
                                startTime={ticketTimeGroup?.startTime}
                                endTime={ticketTimeGroup?.endTime}
                                tickets={ticketTimeGroup?.ticketGroupIds || []}
                                setTime={(field: string, value: number) => { handleFormikChangeInTimings(index, field, value) }}
                                setTickets={(value: any) => { handleFormikChangeInTimings(index, "ticketGroupIds", value) }}
                                deleteTimeGroup={() => { deleteTicketTimeGrouping(index) }}
                            />
                        })}
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                            <Button onClick={addNewBusinessHourTimeText}>Add new Business Hours Text</Button>
                        </Grid>
                        {formik.values.businessHoursTimeTexts?.map((businessHourTimeText: any, index: number) => {
                            return <BusinessHoursTimeText
                                key={`Time Text ${index} ${businessHoursTextsKey}`}
                                startTime={businessHourTimeText?.startTime}
                                endTime={businessHourTimeText?.endTime}
                                text={businessHourTimeText?.text}
                                setBusinessHourTimeText={(field: string, value: number | number) => { handleFormikChangeInBusinessHoursTimeTexts(index, field, value) }}
                                deleteBusinessHourTimeText={() => { deleteBusinessHourTimeText(index) }}
                            />
                        })}
                    </Grid>
                </Grid>
            </Grid>
        }
    </FormDialogLayout>
}