import { createTheme } from "@mui/material";

export const defaultTheme = createTheme({
    // palette: {
    //     primary: {
    //         main: orange[500],
    //     },
    // },
    typography: {
        h1: {
            fontFamily: "DM Sans",
            fontSize: "3rem"
        },
        h2: {
            fontFamily: "DM Sans",
            fontSize: "2rem"
        },
        h3: {
            fontFamily: "DM Sans",
            fontSize: "1.5rem"
        },
        h4: {
            fontFamily: "DM Sans",
            fontSize: "1.25rem"
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: "1rem"
                }
            },
            defaultProps: {
                variant: "contained"
            }
        },
        MuiTextField: {
            // styleOverrides: {
            //     root: {
            //         borderRadius: "1rem"
            //     },
            // },
            defaultProps: {
                size: "small"
            }
        },
        MuiPaper: {
            defaultProps: {
                elevation: 3
            },
            styleOverrides: {
                root: {
                    borderRadius: "1rem"
                }
            }
        }
    }
});