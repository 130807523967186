import { Button, Grid, TextField } from "@mui/material";
import { doc, setDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { db, functions } from "utilities/Firebase/firebase-redux";
import { httpsCallable } from "firebase/functions";
import { MoonLoader } from "react-spinners";

export function WebhookForm() {
    const uid = useSelector((state: any) => state.firebase.auth.uid);
    const connectedAccounts = useSelector((state: any) => state.firestore.ordered.connectedAccounts);

    const [webhookLink, setWebhookLink] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(()=>{
        if(!connectedAccounts || connectedAccounts?.length < 0) return;
        setWebhookLink(connectedAccounts[0]?.webhook || "")
    }, [connectedAccounts])

    function submitFunction(event: any) {
        event?.preventDefault();

        const data = {webhook: webhookLink};
        
        updateWebhooks(data)
            .then((res)=>{
                toast.success("Updated successfully")
            })
            .catch((err)=>{
                toast.error("Error while updating")
            })
    }

    async function updateWebhooks(data: any) {
        const myDoc = doc(db, `/Parks/${uid}/Connected_Accounts/accounts`);
        return setDoc(myDoc, data, {merge: true})
    }

    function testWebhook() {
        setIsLoading(true);
        const testCustomWebhook = httpsCallable(functions, "testCustomWebhook")
        testCustomWebhook()
            .then((res)=>{
                console.log({res})
                toast.success("Webhook sent")
                setIsLoading(false);
            })
            .catch((err)=>{
                console.log({err})
                toast.error("Error while sending Webhook")
                setIsLoading(false);
            })
    }

    return <form onSubmit={submitFunction}>
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <TextField label={"Webhook Link"} fullWidth value={webhookLink} onChange={(e)=>{setWebhookLink(e?.target?.value)}} />
            </Grid>
            <Grid item xs={12}>
                <Button fullWidth type="submit">Update</Button>
            </Grid>
            <Grid item xs={12}>
                <Button disabled={isLoading} fullWidth variant="text" onClick={testWebhook}>
                    Test Webhook
                    <MoonLoader 
                        loading={isLoading}
                        // cssOverride={override}
                        size={25}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />
                </Button>
            </Grid>
        </Grid>
    </form>
}