import { useState } from "react";
import { Grid, IconButton, Paper, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import AreYouSureComponent from "components/base/Dialogs/Warnings/are-you-sure";
import { SettingsListItem } from "components/common/layouts/Settings/Settings-List-Item";
import { addTimingGroupToFirebase, addTimingToFirebase, deleteTicket, deleteTimingGroup } from "../../lib/firebase-helper";
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';
import EditTimeGroupModal from "components/base/Dialogs/Edit-Dialogs/edit-time-group-dialog";
import EditTimingGroupDialog from "./edit-time-group-dialog";
import { GroupingTemplate } from "../../../../common/layouts/Settings/Grouping-Template/grouping-template";

export default function TimingGroup() {
    const uid = useSelector((state: any) => state.firebase.auth.uid);
    const activeSeasonRedux = useSelector((state: any) => state.firestore.ordered.activeSeason);
    const activeSeasonTimingGroupsRedux = useSelector((state: any) => state.firestore.ordered.activeSeasonTimingGroups);

    const [isEditTicketVisible, setEditTicketIsVisible] = useState(false)
    const [editTicketID, setEditTicketID] = useState<string | null>(null)
    const [isAreYouSureVisible, setAreYouSureIsVisible] = useState(false)

    function openEditTiming(id: string) {
        setEditTicketID(id)
        setEditTicketIsVisible(true);
    }

    function closeEditTiming() {
        setEditTicketIsVisible(false);
        setEditTicketID(null)
    }

    function addNewTimingGroup() {
        const newData = {
            Title: "",
            Timings: [],
            CreatedOn: Date.now()
        }

        addTimingGroupToFirebase(newData, uid, activeSeasonRedux[0]?.id)
            .then((res) => {
                const id = res.id;
                openEditTiming(id);
                toast.success("Created Ticket")
            })
            .catch((error) => {
                toast.error("Error while creating Ticket")
                console.log({ error })
            })
    }

    function closeAreYouSureFunction() {
        setAreYouSureIsVisible(false);
    }

    function openAreYouSureFunction() {
        setAreYouSureIsVisible(true);
    }

    function deleteTicketFunction() {
        if (!editTicketID) return;
        deleteTimingGroup(editTicketID, uid, activeSeasonRedux[0]?.id);
        closeAreYouSureFunction();
        closeEditTiming();
    }

    return <Grid container spacing={1} style={{ textAlign: "center", marginTop: '1rem' }}>
        <AreYouSureComponent
            isOpen={isAreYouSureVisible}
            title={`Do you really want to delete this Ticket?`}
            noFunction={closeAreYouSureFunction}
            yesFunction={deleteTicketFunction}
            key={`Ticket-Timing Group`}
        />

        <EditTimingGroupDialog
            visible={isEditTicketVisible}
            closeFunction={closeEditTiming}
            id={editTicketID}
            deleteTicketFunction={openAreYouSureFunction}
            key={`Ticket-Timing Group ${editTicketID}`}
        />

        <GroupingTemplate
            title={"Ticket-Time Groups"}
            addNewFunction={addNewTimingGroup}
            isAddButtonVisible
        >
            {activeSeasonTimingGroupsRedux?.map((timingGoup: any) => {
                return <SettingsListItem
                    key={`Timing Group ${timingGoup.id}`}
                    title={timingGoup?.Title}
                    editFunction={() => { openEditTiming(timingGoup?.id) }}
                />
            })}
        </GroupingTemplate>
    </Grid>
}
