import { Button, Grid, TextField } from "@mui/material";
import { doc, setDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import AccordionCustomComponent from "components/common/layouts/Accordion";
import { PhonePrefixSelect } from "components/common/ui/Selects/Phone-Prefix-Select/phone-prefix-select";
import { db } from "utilities/Firebase/firebase-redux";

export default function ContactInformationComponent() {
    const uid = useSelector((state: any) => state.firebase.auth.uid);
    const park = useSelector((state: any) => state.firestore.ordered.park);

    const [contactPhoneError, setContactPhoneError] = useState(false)
    const [contactEmailError, setContactEmailError] = useState(false)
    const [contactEmail, setContactEmail] = useState('')
    const [contactPhone, setContactPhone] = useState('')
    const [phonePrefix, setPhonePrefix] = useState('+49')

    useEffect(()=>{
        if(!park) return;
        setContactEmail(park[0]?.ContactEmail || "")
        setContactPhone(park[0]?.ContactPhoneWithoutPrefix || "")
        setPhonePrefix(park[0]?.PhonePrefix || "+49")
    }, [park])

    function submitHandler(event: any) {
        event.preventDefault();
        if(!uid) {
            toast.error("Not logged in");
            return;
        }
        const myDoc = doc(db, `/Parks/${uid}`)
        const newData = {
            ContactEmail: contactEmail,
            ContactPhone: `${phonePrefix}${contactPhone}`,
            PhonePrefix: phonePrefix,
            ContactPhoneWithoutPrefix: contactPhone,
        }
        setDoc(myDoc, newData, {merge: true})
            .then(()=>{
                toast.success("Updated Contact Information")
            })
            .catch((err)=>{
                toast.error("There was an error while Updating your Contact Information")
            })
    }


    function isEmailValid(value: string) {
        return (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value))
    }

    function isPhoneValid(value: string) {
        return (/[0-9]*\/*(\+49)*[ ]*(\([0-9]+\))*([ ]*(-|–)*[ ]*[0-9]+)*/g.test(value))
    }

    return <AccordionCustomComponent title={"Contact Information"}>
        <form onSubmit={submitHandler}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <TextField 
                        type={"email"} 
                        error={contactEmailError} 
                        required 
                        fullWidth 
                        label="Contact Email" 
                        size="small" 
                        value={contactEmail} 
                        onChange={(e)=>{setContactEmail(e?.target?.value)}}
                        onBlur={()=>{setContactEmailError(!isEmailValid(contactEmail))}}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={5}>
                            <PhonePrefixSelect 
                                setPhonePrefix={setPhonePrefix} 
                                defaultValue={phonePrefix}
                                required
                            />
                        </Grid>
                        <Grid item xs={7}>
                            <TextField 
                                InputProps={{
                                    startAdornment: <span>{phonePrefix}</span>
                                }}
                                onBlur={()=>{setContactPhoneError(!isPhoneValid(`${phonePrefix}${contactPhone}`))}}
                                type={"tel"} 
                                error={contactPhoneError} 
                                required 
                                fullWidth 
                                label="Contact Phone"
                                size="small" 
                                value={contactPhone} 
                                onChange={(e)=>{setContactPhone(e?.target?.value)}}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" fullWidth type="submit">Update</Button>
                </Grid>
            </Grid>
        </form>
    </AccordionCustomComponent>
}