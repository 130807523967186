import { Button, Grid } from "@mui/material";
import { addDoc, collection } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import EmailTemplatePaperComponent from "./Email-Template-Paper";
import { db } from "../../../../utilities/Firebase/firebase-redux";
import EditEmailTemplateModal from "../../Dialogs/Edit-Dialogs/edit-email-template-modal";

export default function EmailTemplateSettings() {
    const emailTemplates = useSelector((state: any) => state.firestore.ordered.emailTemplates);
    const uid = useSelector((state: any) => state.firebase.auth.uid);

    const [templates, setTemplates] = useState<string[]>([]);
    const [isEditEmailModalVisible, setEditEmailModalIsVisible] = useState(false);
    const [emailTemplateId, setEmailTemplateId] = useState("")

    useEffect(() => {
        if (!emailTemplates) return;
        const newTemplates: string[] = []
        emailTemplates.forEach((template: any) => {
            newTemplates.push(template?.id)
        })
        setTemplates(newTemplates)
    }, [emailTemplates])

    function addNewTemplate() {
        const myCol = collection(db, `/Templates`)
        addDoc(myCol, { title: "", subject: "", html: "", createdOn: Date.now(), uid: uid })
            .then((res) => {
                toast.success("Added a new Template!")
            })
            .catch((err) => {
                toast.error("Failed adding a new Template")
            })
    }

    function openEditModalFunction(id: string) {
        setEmailTemplateId(id);
        setEditEmailModalIsVisible(true);
    }

    return <Grid container spacing={1} style={{ margin: "1rem auto", maxWidth: "500px" }}>
        <EditEmailTemplateModal
            key={`Edit Email Template Modal ${emailTemplateId}`}
            isOpen={isEditEmailModalVisible}
            id={emailTemplateId}
            closeEditEmailTemplateModalFunction={() => { setEditEmailModalIsVisible(false); }} />
        <Grid item xs={12}>
            <Button
                fullWidth
                variant="contained"
                onClick={addNewTemplate}
            >
                Add a new Template
            </Button>
        </Grid>
        {templates.map((id, i) => {
            return <Grid item xs={12} key={`Email Template ${id} ${i}`}>
                <EmailTemplatePaperComponent id={id} openEditModalFunction={() => { openEditModalFunction(id) }} />
            </Grid>
        })}
    </Grid>
}