import { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import { UploadButton } from "../../ui/Buttons/Upload-Button";

export function MediaList({imageOnClickFunction}: {imageOnClickFunction: any}) {
    const uid = useSelector((state: any) => state.firebase.auth.uid);

    const [mediaLinks, setMediaLinks] = useState<string[]>([]);

    const storage = getStorage();

    useEffect(() => {
        if(!uid) return;
        const parkFileRef = ref(storage, `/Parks/${uid}`);
        getFiles(parkFileRef)
    }, [uid])

    async function getFiles(parkFileRef: any) {
        const itemList = await listAll(parkFileRef);
        const items = itemList.items;
        
        const links: string[] = []
        items?.forEach(async (itemRef)=>{
            const link = await getDownloadURL(itemRef);
            links.push(link)
            setMediaLinks([...links]);
        })
    }

    return <div>
        <div style={{display: "flex", justifyContent: "center", textAlign: 'center'}}>
            {mediaLinks
                ?.sort((a: string, b: string)=>{return a.localeCompare(b)})
                ?.map((link)=>{
                return <div style={{border: "1px solid black", padding: "0.5rem", width: '100px', height: '50px', margin: "0 0.5rem"}}>
                    <img onClick={()=>{imageOnClickFunction(link)}} src={link} alt="" style={{maxWidth: '100%', maxHeight: '100%'}}/>
                </div>
            })}
        </div>
        <UploadButton 
            label="Upload New File" 
            location={`/Parks/${uid}/`}
            setLink={(newLink: string)=>{const parkFileRef = ref(storage, `/Parks/${uid}`); getFiles(parkFileRef)}}/>
    </div>
}