import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, TextareaAutosize } from "@mui/material";
import BaseDialogLayout from "components/common/layouts/Dialog";
import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { db } from "utilities/Firebase/firebase-redux";
import AreYouSureComponent from "../Warnings/are-you-sure";

export default function EdiaEmailTemplateModal({ isOpen, id, closeEditEmailTemplateModalFunction }:
    { isOpen: boolean, id: string, closeEditEmailTemplateModalFunction: any }) {

    const emailTemplates = useSelector((state: any) => state.firestore.ordered.emailTemplates);

    const [title, setTitle] = useState("")
    const [subject, setSubject] = useState("")
    const [templateHTML, setTemplateHTML] = useState("")
    const [isAreYouSureVisible, setAreYouSureIsVisible] = useState(false)

    useEffect(() => {
        if (!emailTemplates) return;
        emailTemplates?.forEach((template: any) => {
            if (template?.id !== id) return;
            setTitle(template?.title);
            setSubject(template?.subject)
            setTemplateHTML(template?.html)
        })
    }, [id, emailTemplates])

    function updateTemplate() {
        const myDoc = doc(db, `/Templates/${id}`)
        updateDoc(myDoc, { title, subject, html: templateHTML, updatedLastOn: Date.now() })
            .then((res) => {
                toast.success("Updated Template");
                closeEditEmailTemplateModalFunction();
                console.log({ res })
            })
            .catch((err) => {
                toast.error("Error while updating the Template")
                console.log({ err })
            })
    }

    function deleteTemplate() {
        const myDoc = doc(db, `/Templates/${id}`)
        deleteDoc(myDoc)
            .then((res) => {
                toast.success("Deleted Template");
                closeEditEmailTemplateModalFunction();
                console.log({ res })
            })
            .catch((err) => {
                toast.error("Error while deleting the Template")
                console.log({ err })
            })
    }

    return <BaseDialogLayout
        isOpen={isOpen}
        title={"Edit Email Template"}
        closeFunction={closeEditEmailTemplateModalFunction}
        deletFunction={() => { setAreYouSureIsVisible(true) }}
        updateFunction={updateTemplate}
    >
        <AreYouSureComponent
            isOpen={isAreYouSureVisible}
            title={"Are you sure you want to delete this Event?"}
            noFunction={() => { setAreYouSureIsVisible(false) }}
            yesFunction={deleteTemplate}
        />
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    label="Title"
                    placeholder="Email Template for a Booking"
                    onChange={(e) => { setTitle(e.target.value) }}
                    value={title}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    label="Subject"
                    placeholder="Email Subject for a Booking"
                    onChange={(e) => { setSubject(e.target.value) }}
                    value={subject}
                />
            </Grid>
            <Grid item xs={12}>
                <TextareaAutosize
                    value={templateHTML}
                    onChange={(e: any) => { setTemplateHTML(e.target.value) }}
                    style={{ width: '100%' }}
                    minRows={25}
                    placeholder="HTML Email"
                />
            </Grid>
        </Grid>
    </BaseDialogLayout>
}