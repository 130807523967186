import { setPathnameRedux, setSearchRedux } from "features/historySlice";
import { getIdTokenResult, onAuthStateChanged } from "firebase/auth";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { auth } from "utilities/Firebase/firebase-redux";

export function AuthProtection() {
	const navigate = useNavigate()
	const dispatch = useDispatch()

	// auth protection
	useEffect(() => {
		const pathname = window.location.pathname;
		const search = window.location.search;

		dispatch(setPathnameRedux(pathname))
		dispatch(setSearchRedux(search))

		onAuthStateChanged(auth, (user) => {
			if (!user) {
				toast.error("Not logged in")
				navigate('/login');
				return;
			}
			getIdTokenResult(user, true).then((res) => {
				const claims = res.claims
				const isPark = claims['park'];
				if (!isPark) {
					toast.error("You are not a park User")
					navigate('/login');
					return;
				}
			})
		})
	}, [auth])

	return <></>
}