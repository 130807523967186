import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Button, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import AreYouSureComponent from "components/base/Dialogs/Warnings/are-you-sure";
import { SettingsListItem } from "components/common/layouts/Settings/Settings-List-Item";
import EditTicketDialog from "./edit-ticket-dialog";
import { addTicketToFirebase, deleteTicket } from "../../lib/firebase-helper";
import { GroupingTemplate } from "../../../../common/layouts/Settings/Grouping-Template/grouping-template";

export default function Tickets() {
    const uid = useSelector((state: any) => state.firebase.auth.uid);
    const activeSeasonRedux = useSelector((state: any) => state.firestore.ordered.activeSeason);
    const activeSeasonTicketsRedux = useSelector((state: any) => state.firestore.ordered.activeSeasonTickets);

    const [tickets, setTickets] = useState<any[]>([])
    const [isEditTicketVisible, setEditTicketIsVisible] = useState(false)
    const [editTicketID, setEditTicketID] = useState<string | null>(null)
    const [isAreYouSureVisible, setAreYouSureIsVisible] = useState(false)

    useEffect(() => {
        if (!activeSeasonTicketsRedux) return;
        const newArr: any[] = structuredClone(activeSeasonTicketsRedux)
        newArr?.sort((a: any, b: any) => { return a?.CreatedOn - b?.CreatedOn })
        newArr?.forEach((ticket: any) => {
            ticket.endTimeOfTicket = dayjs(new Date(ticket?.endTimeOfTicket?.seconds * 1000));
            const newTimeData: any[] = []
            ticket.timeData?.forEach((time: any) => {
                newTimeData.push(dayjs(new Date(time?.seconds * 1000)))
            })
            ticket.timeData = newTimeData
        })
        setTickets(newArr)
    }, [activeSeasonTicketsRedux])

    function openEditTicket(id: string) {
        setEditTicketID(id)
        setEditTicketIsVisible(true);
    }

    function closeEditTicket() {
        setEditTicketIsVisible(false);
        setEditTicketID(null)
    }

    function addNewTicket() {
        const newData = {
            Title: "",
            CreatedOn: Date.now()
        }

        addTicketToFirebase(newData, uid, activeSeasonRedux[0]?.id)
            .then((res) => {
                const id = res.id;
                openEditTicket(id);
                toast.success("Created Ticket")
            })
            .catch((error) => {
                toast.error("Error while creating Ticket")
                console.log({ error })
            })
    }

    function closeAreYouSureFunction() {
        setAreYouSureIsVisible(false);
    }

    function openAreYouSureFunction() {
        setAreYouSureIsVisible(true);
    }

    function deleteTicketFunction() {
        if (!editTicketID) return;
        deleteTicket(editTicketID, uid, activeSeasonRedux[0]?.id);
        closeAreYouSureFunction();
        closeEditTicket();
    }

    return <Grid container spacing={1} style={{ textAlign: "center", marginTop: '1rem' }}>
        <AreYouSureComponent
            isOpen={isAreYouSureVisible}
            title={`Do you really want to delete this Ticket?`}
            noFunction={closeAreYouSureFunction}
            yesFunction={deleteTicketFunction}
        />

        <EditTicketDialog
            visible={isEditTicketVisible}
            closeFunction={closeEditTicket}
            key={`Add Ticket Dialog ${editTicketID}`}
            id={editTicketID}
            deleteTicketFunction={openAreYouSureFunction}
        />

        <GroupingTemplate
            title={"Tickets"}
            isAddButtonVisible
            addNewFunction={addNewTicket}
        >
            {tickets?.map((ticket: any) => {
                return <SettingsListItem key={`Grid ${ticket.id}`} title={ticket?.Title || ""} editFunction={() => { openEditTicket(ticket?.id) }} />
            })}
        </GroupingTemplate>

    </Grid>
}
