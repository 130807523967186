import { Button, Grid, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default function EmailTemplatePaperComponent({id, openEditModalFunction}: {id: string, openEditModalFunction: any}) {
    const emailTemplates = useSelector((state: any) => state.firestore.ordered.emailTemplates);

    const [title, setTitle] = useState("")
    const [subject, setSubject] = useState("")
    const [templateHTML, setTemplateHTML] = useState("")
    
    useEffect(()=>{
        if(!emailTemplates) return;
        emailTemplates?.forEach((template: any)=>{
            if(template?.id !== id) return;
            setTitle(template?.title);
            setSubject(template?.subject)
            setTemplateHTML(template?.html)
        })
    }, [id, emailTemplates])
    
    return <Paper elevation={3} style={{borderRadius: "1rem", textAlign: "center", padding: "1rem"}}>
    <Grid container spacing={1}>
        <Grid item xs={12}>
            <Typography typography={"h3"}>{title}</Typography>
        </Grid>
        <Grid item xs={12}>
            <Typography typography={"h4"}>{subject}</Typography>
        </Grid>
        <Grid item xs={12}>
            <div dangerouslySetInnerHTML={{__html: templateHTML}}></div>
        </Grid>
        <Grid item xs={12}>
            <Button fullWidth variant="contained" onClick={openEditModalFunction}>Edit</Button>
        </Grid>
    </Grid>
</Paper>
}