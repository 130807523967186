import { Suspense, useEffect, useState } from "react";
import { useParams } from "react-router";
import CurrentlyOpen from "components/common/components/iFrames/Currently-Open";
import dayjs from "dayjs";
import { getLocaleDateString } from "components/base/Calendars/Business-Hours/lib/date-helper";
import { getBusinessHourDataFromPark } from "../Calendar";

const today = dayjs();

export function CurrentlyOpenPage() {
    const [businessHours, setBusinessHours] = useState<any>({});
    const [parkId, setParkId] = useState<string | undefined>("");
    const params = useParams();

    const [localeDateString, setLocaleDateString] = useState("")

    useEffect(() => {
        const dateString = getLocaleDateString(today?.toDate()?.getTime() || 0);
        setLocaleDateString(dateString)
    }, [today])

    useEffect(() => {
        const parkId = params?.parkid;
        setParkId(parkId)
    }, [params])

    useEffect(() => {
        if (!parkId || !localeDateString) return;
        getBusinessHourDataFromPark(parkId,)
            .then((res) => {
                res.docs.forEach((d) => {
                    if (d?.id !== localeDateString) return;
                    const data = d.data();
                    data.id = d.id;
                    setBusinessHours(data);
                })
            })
    }, [parkId, localeDateString])

    return <div style={{ width: '100vw', height: '100vh', display: 'flex', alignContent: 'center', alignItems: "center", justifyContent: 'center' }}>
        <Suspense>
            <CurrentlyOpen businessHours={businessHours} />
        </Suspense>
    </div>
}