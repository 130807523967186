import { Button, TextField } from "@mui/material";
import { toast } from "react-toastify";

export function CopyButton({ value }: { value: string }) {

    function copyFunction() {
        navigator.clipboard.writeText(value);
        toast.success("Erfolgreich kopiert")
    }

    return <TextField
        label="Link"
        fullWidth
        value={value}
        InputProps={
            {
                endAdornment:
                    <Button
                        variant="text"
                        onClick={copyFunction}
                    >
                        Copy
                    </Button>
            }
        }
    />
}