import { Button, Grid, TextField } from "@mui/material";
import { useState } from "react";
import { sendPasswordResetEmail } from "firebase/auth"
import { toast } from "react-toastify";
import { auth } from "utilities/Firebase/firebase-redux";

export function PasswordResetForm() {
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false)

    function sendPasswordResetMail(event: any) {
        event.preventDefault();
        if(!email) {
            setEmailError(true)
            return;
        }
        setEmailError(false);
        sendPasswordResetEmail(auth, email)
            .then(()=>{
                toast.success("Email sent successfully!")
            })
            .catch((err)=>{
                if(err?.code === "auth/user-not-found") {
                    toast.error("User Account with this Email could not be found")
                    return;
                }
                toast.error("There was an Error sending the Email")
            })
    }

    return <form onSubmit={sendPasswordResetMail}>
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <TextField required type={"email"} error={emailError} label={"Email-Address"} fullWidth value={email} onChange={(e)=>{setEmail(e?.target?.value)}} />
            </Grid>
            <Grid item xs={12}>
                <Button type="submit" fullWidth>Send Password Reset Email</Button>
            </Grid>
        </Grid>
    </form>
}