import { Button, FormControlLabel, FormGroup, Grid, Switch } from "@mui/material"
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { doc, setDoc } from "firebase/firestore";
import AccordionCustomComponent from "components/common/layouts/Accordion";
import { db } from "utilities/Firebase/firebase-redux";

export default function EnableDisablePaymentMethods() {
    const uid = useSelector((state: any) => state.firebase.auth.uid);
    const park = useSelector((state: any) => state.firestore.ordered.park);
    
    // const [isStripeEnabled, setStripeIsEnabled] = useState(false);
    const [isPaymentMethodStripeEnabled, setPaymentMethodStripeIsEnabled] = useState(false);

    useEffect(()=>{
        if(!park) return;
        setPaymentMethodStripeIsEnabled(park[0]?.StripeIsEnabled || false)
    }, [park])

    function updateFunction(e: any) {
        e?.preventDefault();
        if(!uid) {
            toast.error("Not logged in");
            return;
        }
        const myDoc = doc(db, `/Parks/${uid}`)
        const newData = {
            StripeIsEnabled: isPaymentMethodStripeEnabled,
        }
        setDoc(myDoc, newData, {merge: true})
            .then(()=>{
                toast.success("Updated Payment Methods")
            })
            .catch((err)=>{
                toast.error("There was an error while Updating your Payment Methods")
            })
    }

    return <AccordionCustomComponent title={"Enable/Disable Payment Methods"}>
        <form onSubmit={updateFunction}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <FormGroup aria-label="position" row>
                        <FormControlLabel onClick={()=>{setPaymentMethodStripeIsEnabled(!isPaymentMethodStripeEnabled)}} labelPlacement="start" control={<Switch checked={isPaymentMethodStripeEnabled} />} label="Stripe: " />
                    </FormGroup>
                </Grid>

                <Grid item xs={12}>
                    <Button fullWidth variant="contained" type="submit">Update</Button>
                </Grid>
            </Grid>
        </form>
    </AccordionCustomComponent>
}