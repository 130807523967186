import { Button, Checkbox, Divider, Grid, IconButton, Paper, Typography } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone';
import ArrowForwardIosTwoToneIcon from '@mui/icons-material/ArrowForwardIosTwoTone';
import { useSelector } from "react-redux";
import { db, store } from "utilities/Firebase/firebase-redux";
import { toast } from "react-toastify";
import { BusinessHourCalendar } from "./business-hour-calendar";
import { updateParkDataOnFirestore } from "lib/firebase";
import TimingGroup from "components/base/Hours-And-Tickets/Times/timeGroup/timeGroups";
import { updateSeasonFirebase } from "./lib/firebase-helper";

export type BusinessHourType = {
    title: string,
    startDateTime: number,
    endDateTime: number,
    color: string
}

export default function BusinessHours() {
    const seasonsRedux = useSelector((state: any) => state.firestore.ordered.seasons);
    // const businessHoursRedux = useSelector((state: any) => state.firestore.ordered.businessHours);
    const uid = useSelector((state: any) => state.firebase.auth.uid);
    const parkData = useSelector((state: any) => state.firestore.ordered.park);

    const [currentSeason, setCurrentSeason] = useState(1);
    const [startDate, setStartDate] = useState<Dayjs>(dayjs())
    const [endDate, setEndDate] = useState<Dayjs>(dayjs())
    // const [isBusinessHourDialogVisible, setBusinessHourDialogIsVisible] = useState(false)
    // const [businessHourIndex, setBusinessHourIndex] = useState(0)
    // const [businessHours, setBusinessHours] = useState<BusinessHourType[]>([])
    const [isCurrentSeasonActive, setCurrentSeasonIsActive] = useState(false);

    useEffect(() => {
        if (!parkData) return;
        setCurrentSeasonIsActive(parkData[0]?.ActiveSeason === `Season ${currentSeason}`);
    }, [parkData, currentSeason])

    useEffect(() => {
        if (!uid) return;
        store.firestore.onSnapshot({
            collection: 'Parks',
            doc: uid,
            subcollections: [{ collection: "Business-Hours" }],
            storeAs: 'businessHours'
        })
    }, [uid, currentSeason])

    // useEffect(() => {
    //     if (!businessHoursRedux) return;

    //     setBusinessHours(businessHoursRedux || []);
    // }, [businessHoursRedux, currentSeason])

    useEffect(() => {
        if (!seasonsRedux) return;

        function getCurrentSeason() {
            let returnData;
            seasonsRedux?.forEach((season: any) => {
                if (season?.id !== `Season ${currentSeason}`) return;
                returnData = season;
            })
            return returnData;
        }

        const season: any = getCurrentSeason();

        setStartDate(dayjs((season?.StartDateTime || dayjs())));
        setEndDate(dayjs((season?.EndDateTime || dayjs())));
    }, [seasonsRedux, currentSeason])

    // function addNewBusinessHour() {
    //     const businessHoursNew = structuredClone(businessHours);
    //     const newData = {
    //         title: "",
    //         startDateTime: Date.now(),
    //         endDateTime: Date.now(),
    //         color: ""
    //     }
    //     businessHoursNew.push(newData)

    //     addBusinessHourFirebase(newData)
    //         .then((res) => {
    //             toast.success("Added Business Hours successfully");
    //             setBusinessHours(businessHoursNew)

    //             editBusinessHour(businessHoursNew.length - 1);
    //         })
    //         .catch((error) => {
    //             console.error({ error })
    //             toast.error("Error while adding Business Hours");
    //         })
    // }

    // function editBusinessHour(index: number) {
    //     setBusinessHourIndex(index)
    //     setBusinessHourDialogIsVisible(true);
    // }

    // function closeBusinessHourDialog() {
    //     setBusinessHourDialogIsVisible(false);
    // }

    // function deleteBusinessHour(id: string) {
    //     const businessHoursNew = structuredClone(businessHours);
    //     businessHoursNew.splice(businessHourIndex, 1);

    //     deleteBusinessHourFirebase(id)
    //         .then((res) => {
    //             toast.success("Deleted Business Hours successfully");
    //             setBusinessHours(businessHoursNew)

    //             closeBusinessHourDialog();
    //         })
    //         .catch((error) => {
    //             toast.error("Error while deleting Business Hours");
    //             console.error({ error })
    //         })
    // }

    // function updateBusinessHour(newData: BusinessHourType, id: string) {
    //     const businessHoursNew = structuredClone(businessHours);
    //     businessHoursNew[businessHourIndex] = structuredClone(newData);

    //     updateBusinessHourFirebase(newData, id)
    //         .then((res) => {
    //             toast.success("Updated Business Hours successfully");
    //             setBusinessHours(businessHoursNew)

    //             closeBusinessHourDialog();
    //         })
    //         .catch((error) => {
    //             toast.error("Error while updating Business Hours");
    //             console.error({ error })
    //         })
    // }

    function previousSeason() {
        if (currentSeason === 1) return;
        setCurrentSeason(currentSeason - 1);
    }

    function nextSeason() {
        setCurrentSeason(currentSeason + 1);
    }

    function updateDatesFunction() {
        const newData = {
            StartDateTime: startDate.toDate().getTime(),
            EndDateTime: endDate.toDate().getTime(),
            Season: `Season ${currentSeason}`
        }

        updateSeasonFirebase(newData, uid, `Season ${currentSeason}`)
            .then((res) => {
                toast.success("Updated Dates succesfully");
                toast.warning("Please wait for Park Data to update")
            })
            .catch((err) => {
                console.error({ err })
                toast.error("Error while updating Dates");
            })

        const newParkData = {
            ActiveSeason: `Season ${currentSeason}`
        }

        updateParkDataOnFirestore(newParkData, uid)
            .then((res) => {
                toast.success("Updated Active Park succesfully");
            })
            .catch((err) => {
                console.error({ err })
                toast.error("Error while updating Active Park");
            })
    }

    return <Paper elevation={3} style={{ padding: '0.5rem' }}>
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <div style={{ display: "flex", justifyContent: 'space-between', alignContent: 'center', alignItems: 'center' }}>
                    <IconButton disabled={currentSeason === 1}>
                        <ArrowBackIosNewTwoToneIcon onClick={previousSeason} />
                    </IconButton>
                    <Typography typography={"h1"}>Saison {currentSeason}</Typography>
                    <IconButton>
                        <ArrowForwardIosTwoToneIcon onClick={nextSeason} />
                    </IconButton>
                </div>
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
                <Typography
                    onClick={() => { setCurrentSeasonIsActive(!isCurrentSeasonActive) }}
                    typography={"span"}
                    style={{ display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}
                >
                    Saison ist aktiv: <Checkbox checked={isCurrentSeasonActive} />
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
                <DatePicker
                    sx={{ width: '100%' }}
                    label="Start Datum"
                    value={startDate}
                    onChange={(newValue: Dayjs | undefined | null) => { if (!newValue) return; setStartDate(newValue) }}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <DatePicker
                    sx={{ width: '100%' }}
                    label="End Datum"
                    value={endDate}
                    minDate={startDate}
                    onChange={(newValue: Dayjs | undefined | null) => { if (!newValue) return; setEndDate(newValue) }}
                />
            </Grid>
            <Grid item xs={12}>
                <Button fullWidth onClick={updateDatesFunction}>Update</Button>
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <TimingGroup />
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <BusinessHourCalendar
                startDate={startDate}
                endDate={endDate}
                currentSeason={currentSeason}
            />
        </Grid>
    </Paper>
}