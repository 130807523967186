import { Autocomplete, Button, Checkbox, FormControl, Grid, InputLabel, List, ListItem, ListItemText, MenuItem, Select, TextField, TextareaAutosize, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { FormDialogLayout } from "components/common/layouts/Dialog/form-dialog-layout";
import { useFormik } from "formik";
import { updateTicketGroupDoc } from "../../lib/firebase-helper";
import { EmailTemplateSelect } from "components/common/ui/Selects/EmailTemplate-Select";

type TicketPriceType = {
    minVisitors: number,
    maxVisitors: number,
    extraPriceId: string,
    priceIds: any[],
    minVisitorsClosingTicket: number,
    emailTemplateId: string
}

type TicketPriceGroupType = {
    ticketPrices: TicketPriceType[],
}

export default function EditTicketPriceGroupDialog(
    { visible, closeFunction, id, deleteTicketFunction }:
        { visible: boolean, closeFunction: any, id: string | null, deleteTicketFunction: any }
) {
    const uid = useSelector((state: any) => state.firebase.auth.uid);
    const activeSeasonRedux = useSelector((state: any) => state.firestore.ordered.activeSeason);
    const activeSeasonTicketGroupingsRedux = useSelector((state: any) => state.firestore.ordered.activeSeasonTicketsGroups);
    const activeSeasonPricesRedux = useSelector((state: any) => state.firestore.ordered.activeSeasonPrices);
    const activeSeasonTicketsRedux = useSelector((state: any) => state.firestore.ordered.activeSeasonTickets);

    const [pricesSorted, setPricesSorted] = useState<any[]>([])
    const [activeTicketGrouping, setActiveTicketGrouping] = useState<any>();
    const [isLoading, setIsLoading] = useState(false);

    const formik = useFormik<TicketPriceGroupType>({
        initialValues: {
            ticketPrices: []
        },
        onSubmit: updateTicket,
    });

    function getTicketData(ticketId: string) {
        let returnVal;
        activeSeasonTicketsRedux.forEach((ticket: any) => {
            if (ticket.id !== ticketId) return;
            returnVal = ticket;
        })
        return returnVal;
    }

    useEffect(() => {
        if (!activeSeasonPricesRedux) return;
        const newArr = structuredClone(activeSeasonPricesRedux);
        newArr?.sort((a: any, b: any) => { return String(a?.Title).localeCompare(b?.Title) })
        setPricesSorted(newArr)
    }, [activeSeasonPricesRedux])

    useEffect(() => {
        if (!id) return;
        activeSeasonTicketGroupingsRedux?.forEach((ticketData: any) => {
            if (ticketData.id !== id) return;
            formik.setFieldValue("ticketPrices", ticketData?.TicketPrices || [])
            setActiveTicketGrouping(ticketData);
        })
    }, [activeSeasonTicketGroupingsRedux, id])

    function updateTicket(values: TicketPriceGroupType) {
        if (!id) return;
        setIsLoading(true)

        const newData = {
            TicketPrices: values.ticketPrices,
            UpdatedOn: Date.now()
        }

        updateTicketGroupDoc(id, newData, uid, activeSeasonRedux[0]?.id)
            .then(() => {
                setIsLoading(false);
                toast.success("Updated Ticket Price Group")
                closeFunction()
            })
            .catch((error) => {
                setIsLoading(false);
                toast.error("Error while updating Ticket Price Group")
                console.log({ error })
            })
    }

    function addGrouping() {
        const newArr = structuredClone(formik.values.ticketPrices);
        newArr.push({
            minVisitors: 1,
            maxVisitors: 2,
            extraPriceId: "",
            minVisitorsClosingTicket: -1,
            priceIds: activeTicketGrouping?.TicketIds?.map(() => ""),
            emailTemplateId: ""
        })
        formik.setFieldValue("ticketPrices", newArr)
    }

    function handleFormikChangeInMap(ticketPriceIndex: number, field: string, value: number | string) {
        const newArr = structuredClone(formik.values.ticketPrices);
        const nowObj: any = newArr[ticketPriceIndex];
        nowObj[field] = value;
        formik.setFieldValue("ticketPrices", newArr)
    }

    function handleFormikPriceIdChangeInMap(ticketPriceIndex: number, value: any, priceIdIndex: number) {
        const newArr = structuredClone(formik.values.ticketPrices);
        const nowObj: any = newArr[ticketPriceIndex];
        if (nowObj.priceIds?.length < priceIdIndex) nowObj.priceIds.push(value);
        else nowObj.priceIds[priceIdIndex] = value;
        formik.setFieldValue("ticketPrices", newArr)
    }

    return <FormDialogLayout
        isOpen={visible}
        title={"Edit Ticket Price Grouping"}
        closeFunction={closeFunction}
        deletFunction={deleteTicketFunction}
        submitFunction={formik.handleSubmit}
        loading={isLoading}
    >
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Button onClick={addGrouping} fullWidth>Add a new grouping</Button>
            </Grid>
            <Grid item xs={12}>
                {formik?.values?.ticketPrices?.map((priceGroup: any, index: number) => {
                    return <div style={{ border: '1px solid black', margin: '0.5rem auto', padding: '0.5rem' }}>
                        <TextField
                            fullWidth
                            // error={formik.touched.minVisitors && Boolean(formik.errors.minVisitors)}
                            label={"Min Visitors"}
                            placeholder={"1"}
                            size="small"
                            type="number"
                            value={priceGroup?.minVisitors}
                            onChange={(e) => { handleFormikChangeInMap(index, "minVisitors", Number(e?.target?.value)) }}
                            required
                        />
                        <TextField
                            fullWidth
                            // error={formik.touched.minVisitors && Boolean(formik.errors.minVisitors)}
                            label={"Max Visitors"}
                            placeholder={"1"}
                            size="small"
                            type="number"
                            value={priceGroup?.maxVisitors}
                            onChange={(e) => { handleFormikChangeInMap(index, "maxVisitors", Number(e?.target?.value)) }}
                            required
                        />
                        <TextField
                            fullWidth
                            // error={formik.touched.minVisitors && Boolean(formik.errors.minVisitors)}
                            label={"Closes at min Visitors"}
                            placeholder={"1"}
                            size="small"
                            type="number"
                            value={priceGroup?.maxVisitorsClosingTicket}
                            onChange={(e) => { handleFormikChangeInMap(index, "maxVisitorsClosingTicket", Number(e?.target?.value)) }}
                            required
                        />
                        <PricingSelect
                            title="Aufpreis"
                            value={priceGroup?.extraPriceId || ""}
                            onChange={(data: any) => { handleFormikChangeInMap(index, "extraPriceId", String(data)) }}
                        >
                            {pricesSorted?.map((price: any) => {
                                return <MenuItem value={price?.id}><b>{price?.Title}</b>: {price?.Price}€</MenuItem>
                            })}
                        </PricingSelect>
                        <EmailTemplateSelect
                            setTemplate={(data: any) => { handleFormikChangeInMap(index, "emailTemplateId", String(data)) }}
                            defaultValue={priceGroup?.emailTemplateId || ""}
                            key={`Email Template ${priceGroup?.emailTemplateId}`}
                        />
                        <List>
                            {activeTicketGrouping?.TicketIds?.map((ticketId: string, priceIndex: number) => {
                                const ticket: any = getTicketData(ticketId)
                                return <ListItem>
                                    <ListItemText>
                                        {ticket?.Title}
                                    </ListItemText>
                                    <PricingSelect
                                        value={priceGroup?.priceIds[priceIndex]?.priceId || ""}
                                        onChange={(data: any) => { handleFormikPriceIdChangeInMap(index, { ticketId: ticketId, priceId: data }, priceIndex) }}
                                    >
                                        {pricesSorted?.map((price: any) => {
                                            return <MenuItem value={price?.id}><b>{price?.Title}</b>: {price?.Price}€</MenuItem>
                                        })}
                                    </PricingSelect>
                                </ListItem>
                            })}
                        </List>
                    </div>
                })}
            </Grid>
        </Grid>
    </FormDialogLayout>
}


function PricingSelect({ children, value, onChange, title = "Pricing" }:
    { children?: any, value: string, onChange: any, title?: string }) {

    return <FormControl fullWidth>
        <InputLabel id="pricing-select-label">{title}</InputLabel>
        <Select
            labelId="pricing-select-label"
            id="pricing-select"
            value={value}
            label="Pricing"
            onChange={(e: any) => { onChange(e?.target?.value) }}
        >
            {children}
        </Select>
    </FormControl>
}