import { Button, Grid, Paper, Typography } from "@mui/material";

export function SettingsListItem({ title, editFunction, buttonText = "Edit", children }:
    { title: string, editFunction: any, buttonText?: string, children?: any }) {

    return <Paper elevation={3} style={{ maxWidth: "500px", margin: "1rem auto", borderRadius: "1rem", padding: '0.5rem' }}>
        <Grid container spacing={1}>
            <Grid item xs={12} style={{ display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
                <Typography typography={"h3"}>{title}</Typography>
            </Grid>
            {children}
            <Grid item xs={12}>
                <Button onClick={editFunction} style={{ borderRadius: "1rem" }} fullWidth variant="contained">{buttonText}</Button>
            </Grid>
        </Grid>
    </Paper>
}