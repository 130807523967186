import { Grid, IconButton, Paper, TextareaAutosize } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import ClearTwoToneIcon from '@mui/icons-material/ClearTwoTone';

export function BusinessHoursTimeText({ startTime, endTime, text, setBusinessHourTimeText, deleteBusinessHourTimeText }:
    { startTime: number, endTime: number, text: string, setBusinessHourTimeText: any; deleteBusinessHourTimeText: any }) {
    const [startTimeLocal, setStartTimeLocal] = useState<Dayjs | null>(dayjs(startTime || 0))
    const [endTimeLocal, setEndTimeLocal] = useState<Dayjs | null>(dayjs(endTime || 0))
    const [textLocal, setTextLocal] = useState(text || "")

    useEffect(() => {
        setBusinessHourTimeText("startTime", startTimeLocal?.toDate()?.getTime())
    }, [startTimeLocal])

    useEffect(() => {
        setBusinessHourTimeText("endTime", endTimeLocal?.toDate()?.getTime())
    }, [endTimeLocal])

    useEffect(() => {
        setBusinessHourTimeText("text", textLocal)
    }, [textLocal])

    return <Grid item xs={12}>
        <Paper style={{ display: 'flex', width: '100%', padding: '1rem 0.1rem' }}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                    <TimePicker
                        label="Öffnungszeit"
                        onChange={(newTime) => { if (!newTime) return; setStartTimeLocal(newTime) }}
                        value={startTimeLocal}
                        sx={{ width: '100%' }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TimePicker
                        label="Schließzeit"
                        onChange={(newTime) => { if (!newTime) return; setEndTimeLocal(newTime) }}
                        value={endTimeLocal}
                        sx={{ width: '100%' }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextareaAutosize
                        style={{ width: '100%' }}
                        value={textLocal}
                        onChange={(e) => { setTextLocal(e?.target?.value) }}
                        minRows={2}
                        placeholder="Text den es zwischen diesen Zeiten zeigen soll. Variablen: {{startTime}} und {{endTime}}"
                    />
                </Grid>
            </Grid>
            <IconButton onClick={deleteBusinessHourTimeText}>
                <ClearTwoToneIcon />
            </IconButton>
        </Paper>
    </Grid>
}