import { Autocomplete, Checkbox, Grid, IconButton, Paper, TextField } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ClearTwoToneIcon from '@mui/icons-material/ClearTwoTone';

export function TimeTicketGroupingItem({ startTime, endTime, tickets, setTime, setTickets, deleteTimeGroup }:
    { startTime: number, endTime: number, tickets: any[], setTime: any, setTickets: any; deleteTimeGroup: any }) {

    const activeSeasonTicketGroupingsRedux = useSelector((state: any) => state.firestore.ordered.activeSeasonTicketsGroups);

    const [startTimeLocal, setStartTimeLocal] = useState<Dayjs | null>(dayjs(startTime || 0))
    const [endTimeLocal, setEndTimeLocal] = useState<Dayjs | null>(dayjs(endTime || 0))

    useEffect(() => {
        setTime("startTime", startTimeLocal?.toDate()?.getTime())
    }, [startTimeLocal])

    useEffect(() => {
        setTime("endTime", endTimeLocal?.toDate()?.getTime())
    }, [endTimeLocal])

    function onChangeTickets(event: any, options: any, eventType: string, option: any) {
        const ticketId = option?.option?.id;
        if (!ticketId) return;

        const newArr: any[] = structuredClone(tickets);

        const optionIndex = newArr.indexOf(ticketId);
        if (optionIndex >= 0) newArr.splice(optionIndex, 1);
        else newArr.push(ticketId)

        setTickets(newArr)
    }

    function findTicketFromTicketId(ticketId: string) {
        var returnOption: any = {};
        activeSeasonTicketGroupingsRedux?.forEach((ticket: any) => {
            if (ticket?.id !== ticketId) return;
            returnOption = ticket;
        })
        return returnOption
    }

    function getOptionLabel(option: any) {
        const ticketData = findTicketFromTicketId(option);
        return `${ticketData?.Title}`
    }

    return <Grid item xs={12}>
        <Paper style={{ display: 'flex', width: '100%', padding: '1rem 0.1rem' }}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                    <TimePicker
                        value={startTimeLocal}
                        onChange={(e) => { setStartTimeLocal(e) }}
                        label="Start Time"
                        sx={{ width: '100%' }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TimePicker
                        value={endTimeLocal}
                        onChange={(e) => { setEndTimeLocal(e) }}
                        label="End Time"
                        sx={{ width: '100%' }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        fullWidth
                        multiple
                        onChange={onChangeTickets}
                        value={tickets}
                        limitTags={1}
                        options={activeSeasonTicketGroupingsRedux}
                        disableCloseOnSelect
                        getOptionLabel={getOptionLabel}
                        renderOption={(props, option, { selected }) => {
                            return <li {...props} key={`Time Ticket Checkbox Item ${option?.id}`}>
                                <Checkbox
                                    style={{ marginRight: 8 }}
                                    checked={tickets?.includes(option?.id)}
                                />
                                {option?.Title}
                            </li>
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label="Tickets" placeholder="" fullWidth />
                        )}
                    />
                </Grid>
            </Grid>
            <IconButton onClick={deleteTimeGroup}>
                <ClearTwoToneIcon />
            </IconButton>
        </Paper>
    </Grid>
}
