import { Checkbox, Grid, TextField, TextareaAutosize, Typography } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { FormDialogLayout } from "components/common/layouts/Dialog/form-dialog-layout";
import { useFormik } from "formik";
import { updateTicketDoc } from "../../lib/firebase-helper";

type TicketType = {
	duration: number,
	title: string,
	isDurationUsed: boolean,
	description: string,
}

export default function EditTicketDialog(
	{ visible, closeFunction, id, deleteTicketFunction }:
		{ visible: boolean, closeFunction: any, id: string | null, deleteTicketFunction: any }
) {
	const uid = useSelector((state: any) => state.firebase.auth.uid);
	const activeSeasonRedux = useSelector((state: any) => state.firestore.ordered.activeSeason);
	const activeSeasonTicketsRedux = useSelector((state: any) => state.firestore.ordered.activeSeasonTickets);

	const [isLoading, setIsLoading] = useState(false);

	const formik = useFormik<TicketType>({
		initialValues: {
			duration: 0,
			title: "",
			isDurationUsed: false,
			description: "",
		},
		// validationSchema: PersonalInformationSchema,
		onSubmit: updateTicket,
	});

	useEffect(() => {
		if (!id) return;
		activeSeasonTicketsRedux?.forEach((ticketData: any) => {
			if (ticketData.id !== id) return;
			formik.setFieldValue("title", ticketData?.Title || "")
			formik.setFieldValue("description", ticketData?.Description || "")
			formik.setFieldValue("isDurationUsed", ticketData.IsDurationUsed || false)
			formik.setFieldValue("duration", (ticketData?.Duration || 0))
		})
	}, [activeSeasonTicketsRedux, id])

	function updateTicket(values: TicketType) {
		if (!id) return;
		setIsLoading(true)

		const newData = {
			Title: values.title,
			Duration: values.duration,
			Description: values.description,
			IsDurationUsed: values.isDurationUsed,
			UpdatedOn: Date.now()
		}

		updateTicketDoc(id, newData, uid, activeSeasonRedux[0]?.id)
			.then(() => {
				setIsLoading(false);
				toast.success("Updated Ticket")
				closeFunction()
			})
			.catch((error) => {
				setIsLoading(false);
				toast.error("Error while updating Ticket")
				console.log({ error })
			})
	}

	return <FormDialogLayout
		isOpen={visible}
		title={"Edit Ticket"}
		closeFunction={closeFunction}
		deletFunction={deleteTicketFunction}
		submitFunction={formik.handleSubmit}
		loading={isLoading}
	>
		<Grid container spacing={1}>
			<Grid item xs={12} style={{ display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
				<TextField
					fullWidth
					error={formik.touched.title && Boolean(formik.errors.title)}
					label={"Title"}
					placeholder={"Name this Ticket"}
					size="small"
					value={formik.values.title}
					onChange={formik.handleChange}
					name="title"
					required
				/>
			</Grid>
			<Grid item xs={12}>
				<TextareaAutosize
					style={{ width: "100%" }}
					minRows={5}
					placeholder="Description"
					name="description"
					value={formik.values.description}
					onChange={formik.handleChange}
				/>
			</Grid>
			<Grid item xs={12} style={{ textAlign: 'center' }}>
				<Typography
					typography={"span"}
					onClick={() => { formik.setFieldValue("isDurationUsed", !formik.values.isDurationUsed) }}
				>
					Ticket has a duration*: <Checkbox checked={formik.values.isDurationUsed} />
				</Typography>
				<Typography typography={"span"}>* Ticket will still end at Business Hours End Time</Typography>
			</Grid>
			<Grid item xs={12}>
				<TextField
					disabled={!formik.values.isDurationUsed}
					fullWidth
					value={Number(dayjs(formik.values.duration || 0).tz("Europe/Berlin").toDate().getTime() / 60 / 60 / 1000).toString()}
					onChange={(e: any) => { formik.setFieldValue("duration", dayjs(Number((e?.target?.value)) * 60 * 60 * 1000).tz("Europe/Berlin").toDate().getTime()); }}
					name={"duration"}
					label={"Zeit in Stunden"}
					type="number"
				/>
			</Grid>
		</Grid>
	</FormDialogLayout>
}