import { Grid } from "@mui/material";
import { Prices } from "components/base/Hours-And-Tickets/Prices/prices";
import { TicketPriceGrouping } from "components/base/Hours-And-Tickets/Prices/ticketPriceGroup/ticket-price-grouping";
import { PrivatePageLayout } from "components/common/layouts/Page/private";

export function PricesPage() {
    return <PrivatePageLayout
        link={"https://images.pexels.com/photos/1834407/pexels-photo-1834407.jpeg?auto=compress&cs=tinysrgb"}
        activeLink={"tickets,prices,times"}
        activeSubLink={"prices"}
        title="Preise"
    >
        <Grid container style={{ maxWidth: "1000px", margin: 'auto' }} spacing={0}>
            <Grid item xs={12}>
                <Prices />
            </Grid>
            <Grid item xs={12}>
                <TicketPriceGrouping />
            </Grid>
        </Grid>
    </PrivatePageLayout>
}