import { MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import COUNTRIES from "../../../../../data/countries.json"
// https://stefangabos.github.io/world_countries/

export default function CountryDropdown({setCountry, defaultCountry = "", required}: 
    {setCountry: any; defaultCountry?: string; required?: boolean}) {
    const [select, setSelect] = useState(defaultCountry)

    useEffect(()=>{
        setCountry(select);
    }, [select])

    return <Select required={required} fullWidth value={select} onChange={(e: any) => { setSelect(e.target.value); }}>
        {COUNTRIES?.map((country)=>{
            return <MenuItem value={country?.alpha2} key={`Language ${country?.en}`}>{country?.en}</MenuItem>
        })}
    </Select>;
}