import { Button, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { doc, updateDoc } from "firebase/firestore";
import { useTranslation } from "react-i18next";
import AccordionCustomComponent from "components/common/layouts/Accordion";
import { db } from "utilities/Firebase/firebase-redux";
import LanguageDropdown from "components/common/ui/Selects/Language-Select/language-select";
import CurrencyDropdown from "components/common/ui/Selects/Currency-Select/currency-select";
import TimeFormatSystemDropdown from "components/common/ui/Selects/TimeFormat-Select/time-format";
import MassSystemDropdown from "components/common/ui/Selects/MassSystem-Select/mass-system-select";
import DistanceSystemDropdown from "components/common/ui/Selects/DistanceSystem-Select/distance-system-select";

export default function LocalizationDetails() {
    const uid = useSelector((state: any) => state.firebase.auth.uid);
    const park = useSelector((state: any) => state.firestore.ordered.park);
    
    const [language, setLanguage] = useState('')
    const [currency, setCurrency] = useState('EUR')
    const [massSystem, setMassSystem] = useState('')
    const [distanceSystem, setDistanceSystem] = useState('')
    const [timeFormat, setTimeFormat] = useState('')

    const { t, i18n } = useTranslation('common');

    useEffect(()=>{
        if(!language || language === "") return;
        i18n.changeLanguage(language)
    }, [language])

    useEffect(()=>{
        if(!park || park?.length <= 0) return;
        setLanguage(park[0]?.Language || "")
        setCurrency(park[0]?.Currency || "EUR")
        setMassSystem(park[0]?.MassSystem || "")
        setDistanceSystem(park[0]?.DistanceSystem || "")
        setTimeFormat(park[0]?.TimeFormat || "")
    }, [park])

    function saveAndNext(event: any) {
        event.preventDefault();
        if(!uid) {
            toast.error("Not logged in");
            return;
        }
        const myDoc = doc(db, `/Parks/${uid}`)
        const newData = {
            Language: language,
            Currency: currency,
            MassSystem: massSystem,
            DistanceSystem: distanceSystem,
            TimeFormat: timeFormat,
        }
        updateDoc(myDoc, newData)
            .then(()=>{
                toast.success(t('toast.success.localization.updated'))
            })
            .catch((err)=>{
                toast.error(t('toast.error.localization.updated'))
            })
    }

    return <AccordionCustomComponent title={t('text.localization')}>
        <form onSubmit={saveAndNext}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography>{t('select.language')}</Typography>
                    <LanguageDropdown key={`Language Dropdown ${language}`} setLanguage={setLanguage} defaultLanguage={language} />
                </Grid>
                <Grid item xs={12}>
                    <Typography>{t('select.currency')}</Typography>
                    <CurrencyDropdown setCurrency={setCurrency} defaultCurrency={currency} />
                </Grid>
                {/* <Grid item xs={12}>
                    <Typography>Select TimeZone</Typography>
                    <TimeZoneDropdown />
                </Grid> */}
                {/* <Grid item xs={12}>
                    <Typography>Select Date Format</Typography>
                    <DateFormatDropdown />
                </Grid> */}
                <Grid item xs={12}>
                    <Typography>{t('select.timeFormat')}</Typography>
                    <TimeFormatSystemDropdown key={`Time Format Dropdown ${timeFormat}`} defaultValue={timeFormat} setTimeFormat={setTimeFormat} />
                </Grid>
                <Grid item xs={12}>
                    <Typography>{t('select.massSystem')}</Typography>
                    <MassSystemDropdown key={`Mass System Dropdown ${massSystem}`} defaultValue={massSystem} setMassSystem={setMassSystem} />
                </Grid>
                <Grid item xs={12}>
                    <Typography>{t('select.distanceSystem')}</Typography>
                    <DistanceSystemDropdown key={`Distance System Dropdown ${distanceSystem}`} defaultValue={distanceSystem} setDistanceSystem={setDistanceSystem} />
                </Grid>
                <Grid item xs={12}>
                    <Button fullWidth variant="contained" onClick={saveAndNext}>{t('button.save')}</Button>
                </Grid>
            </Grid>
        </form>
    </AccordionCustomComponent>
}