import { Paper, Typography } from "@mui/material"
import { PasswordResetForm } from "./Password-Reset-Form"
import { useNavigate } from "react-router"
import { BackButton } from "components/common/ui/Buttons/Back-Button"

export function PasswordResetPaper() {
    const navigate = useNavigate()
    
    return <Paper elevation={3} style={{ maxWidth: '500px', textAlign: 'center', margin: 'auto', padding: '1rem', borderRadius: '1rem', opacity: '1', backgroundColor: "white", backdropFilter: 'blur(150px)', WebkitBackdropFilter: 'blur(15p0x)', position: 'relative' }}>
        <div style={{position: 'absolute', top: 0, left: 0}}>
            <BackButton onClick={()=>{navigate('/login')}} />
        </div>
        <Typography typography={"h1"}>Password Reset</Typography>
        <PasswordResetForm />
    </Paper>
}