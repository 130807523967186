import { Button, Grid } from "@mui/material"
import { addPriceToFirebase, deletePrice } from "../lib/firebase-helper";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import EditPriceDialog from "./edit-price-dialog";
import { useState } from "react";
import { SettingsListItem } from "components/common/layouts/Settings/Settings-List-Item";
import AreYouSureComponent from "components/base/Dialogs/Warnings/are-you-sure";
import { GroupingTemplate } from "../../../common/layouts/Settings/Grouping-Template/grouping-template";

export function Prices() {
    const uid = useSelector((state: any) => state.firebase.auth.uid);
    const activeSeasonRedux = useSelector((state: any) => state.firestore.ordered.activeSeason);
    const activeSeasonPricesRedux = useSelector((state: any) => state.firestore.ordered.activeSeasonPrices);

    const [isEditPriceVisible, setEditPriceIsVisible] = useState(false)
    const [editPriceID, setEditPriceID] = useState<string | null>(null)
    const [isAreYouSureVisible, setAreYouSureIsVisible] = useState(false)

    function addNewPrice() {
        const newData = {
            Title: "",
            Price: 0,
            CreatedOn: Date.now()
        }

        addPriceToFirebase(newData, uid, activeSeasonRedux[0]?.id)
            .then((res) => {
                const id = res.id;
                openEditPrice(id);
                toast.success("Created Ticket")
            })
            .catch((error) => {
                toast.error("Error while creating Ticket")
                console.log({ error })
            })
    }

    function openEditPrice(id: string) {
        setEditPriceID(id)
        setEditPriceIsVisible(true);
    }

    function closeEditPrice() {
        setEditPriceIsVisible(false);
        setEditPriceID(null)
    }

    function deletePriceFunction() {
        if (!editPriceID) return;
        deletePrice(editPriceID, uid, activeSeasonRedux[0]?.id);
        closeAreYouSureFunction();
        closeEditPrice();
    }

    function closeAreYouSureFunction() {
        setAreYouSureIsVisible(false);
    }

    function openAreYouSureFunction() {
        setAreYouSureIsVisible(true);
    }

    return <Grid container spacing={1} style={{ textAlign: "center", marginTop: '1rem' }}>
        <AreYouSureComponent
            isOpen={isAreYouSureVisible}
            title={`Do you really want to delete this Ticket?`}
            noFunction={closeAreYouSureFunction}
            yesFunction={deletePriceFunction}
        />

        <EditPriceDialog
            visible={isEditPriceVisible}
            closeFunction={closeEditPrice}
            id={editPriceID}
            deleteTicketFunction={openAreYouSureFunction}
        />

        <GroupingTemplate
            title={"Prices"}
            addNewFunction={addNewPrice}
            isAddButtonVisible
        >
            {activeSeasonPricesRedux?.map((price: any) => {
                return <SettingsListItem key={`Grid ${price.id}`} title={price?.Title || ""} editFunction={() => { openEditPrice(price?.id) }} />
            })}
        </GroupingTemplate>
    </Grid>
}