import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Badge, Paper } from "@mui/material";
import WhyDidDataChangeModal from "components/base/Dialogs/old/why-did-data-change";
import { DateCalendar, DayCalendarSkeleton, PickersDay, PickersDayProps } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { EditBusinessDayDialog } from "./edit-business-day-dialog";

// no deletiong / editing of business hours that have passed
// Inform how many Buchungen are affected (We will inform them of that change)

export default function EditDaysSetting() {
    const activeSeasonBusinessHoursRedux = useSelector((state: any) => state.firestore.ordered.activeSeasonBusinessHours);
    const activeSeasonRedux = useSelector((state: any) => state.firestore.ordered.activeSeason);

    const [startTime, setStartTime] = useState(dayjs())
    const [endTime, setEndTime] = useState(dayjs())
    const [selectedDate, setSelectedDate] = useState(dayjs())
    const [isEditEventDialogVisible, setEditEventDialogIsVisible] = useState(false);
    const [activeSeason, setActiveSeason] = useState("");

    useEffect(() => {
        if (!activeSeasonRedux) return;
        setStartTime(dayjs(activeSeasonRedux[0]?.StartDateTime || 0))
        setEndTime(dayjs(activeSeasonRedux[0]?.EndDateTime || 0))
        setActiveSeason(activeSeasonRedux[0]?.id || "")
    }, [activeSeasonRedux])

    function onCalendarDateClick(dayjsDate: Dayjs | null) {
        if (!dayjsDate) return;
        setSelectedDate(dayjsDate)
        setEditEventDialogIsVisible(true);
    }

    function closeEditBusinessDayDialog() {
        setEditEventDialogIsVisible(false);
    }

    return <div>
        {/* <WhyDidDataChangeModal
            key={`WhyDidDataChangeModal ${whyDidDataChangeKey}`}
            visible={isWhyDidDataChangeVisible}
            closeFunction={closeWhyDidDataChangeModal}
        /> */}
        <EditBusinessDayDialog
            visible={isEditEventDialogVisible}
            date={selectedDate}
            closeFunction={closeEditBusinessDayDialog}
            key={`Edit Days Dialog ${selectedDate?.toDate()?.getTime()}`}
            activeSeason={activeSeason}
        />
        <Paper style={{ padding: '0.5rem', margin: "0.5rem auto auto auto", maxWidth: "1200px" }}>
            <DateCalendar
                minDate={startTime}
                maxDate={endTime}
                value={dayjs()}
                showDaysOutsideCurrentMonth
                onChange={onCalendarDateClick}
                renderLoading={() => <DayCalendarSkeleton />}
                slots={{
                    day: ServerDay,
                }}
                slotProps={{
                    day: {
                        highlighted: activeSeasonBusinessHoursRedux
                    } as any,
                }}
            />
        </Paper>
    </div>
}

interface PickersDayPropsExtended extends PickersDayProps<Dayjs> {
    highlighted?: any[],
}

function ServerDay(props: PickersDayPropsExtended) {
    const { highlighted = [], day, outsideCurrentMonth, today, ...other } = props;

    const date = day.toDate();
    const dateTime = date.getTime();

    let color = "";
    highlighted.forEach((businessHour) => {
        const businessHourDateTime = businessHour?.Start?.DateTime;
        if (businessHourDateTime !== dateTime) return;
        color = businessHour?.Color;
    })

    return (
        <Badge
            key={props.day.toString()}
            overlap="circular"
        >
            <PickersDay
                style={{ backgroundColor: color }}
                outsideCurrentMonth={outsideCurrentMonth}
                day={day}
                {...other}
            />
        </Badge>
    );
}