import { Typography, Modal, Dialog, DialogTitle, Button, DialogContent, DialogActions, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, TextareaAutosize } from "@mui/material";
import { useState } from "react";

export default function WhyDidReservierungChangeModal({ visible, saveFunction, eventName = "Event" }:
    { visible: boolean; saveFunction: any; eventName: string }) {

    const [whichAnswer, setWhichAnswer] = useState<string>("")
    const [moreInfoText, setMoreInfoText] = useState("")
    const [otherTextError, setOtherTextError] = useState(false)
    const [whichAnswerError, setWhichAnswerError] = useState(false)

    function saveFunc() {
        if (whichAnswer == "") {
            setWhichAnswerError(true);
            return;
        } else {
            setWhichAnswerError(false);
        }
        const answerData = {
            answer: whichAnswer,
            moreInfo: moreInfoText
        }
        saveFunction(answerData)
    }

    return <Modal open={visible}>
        <Dialog open>
            <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', padding: '0.5rem' }}>
                <h1 style={{ margin: 0, padding: 0, fontWeight: '400', fontSize: '1.5rem' }}>Why did {eventName} Change?</h1>
            </DialogTitle>
            <DialogContent>
                <FormControl>
                    <FormLabel id="radio-buttons-group-label">Select one Option:</FormLabel>
                    <RadioGroup
                        value={whichAnswer}
                        aria-labelledby="radio-buttons-group-label"
                        name="radio-buttons-group"
                        onChange={(e) => { setWhichAnswer(e.target.value) }}
                    >
                        <FormControlLabel value="customer_change" control={<Radio />} label="Customer wished for a change" />
                        <FormControlLabel value="customer_mistake" control={<Radio />} label="Customer made a mistake" />
                        <FormControlLabel value="system_mistake" control={<Radio />} label="The System made a mistake" />
                        <FormControlLabel value="other" control={<Radio />} label={"Other"} />
                    </RadioGroup>
                </FormControl>
                {whichAnswerError && <p style={{ textAlign: 'center' }}>You need to select at least One Option</p>}

                <Typography>Give us more Information:</Typography>
                <TextareaAutosize value={moreInfoText} onChange={(e) => { setMoreInfoText(e.target.value) }} style={{ width: '100%' }} minRows={3} />

            </DialogContent>
            <DialogActions>
                <Button onClick={saveFunc}>Save</Button>
            </DialogActions>
        </Dialog>
    </Modal>
}