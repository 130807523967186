import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";

export default function BaseDialogLayout(
	{ isOpen, title, closeFunction, deletFunction, updateFunction, children }:
		{ isOpen: boolean, title: string, closeFunction: any, deletFunction: any, updateFunction: any, children?: any }
) {

	return <Dialog open={isOpen}>
		<DialogTitle variant="h2" style={{ display: 'flex', justifyContent: 'space-between', padding: '0.5rem' }}>
			{title}
			<IconButton onClick={closeFunction}>
				<CloseOutlined />
			</IconButton>
		</DialogTitle>
		<DialogContent style={{ padding: "1rem" }}>
			{children}
		</DialogContent>
		<DialogActions>
			<div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
				<Button color="error" variant="contained" onClick={deletFunction}>Delete</Button>
				<Button variant="contained" onClick={updateFunction}>Update</Button>
			</div>
		</DialogActions>
	</Dialog>
}