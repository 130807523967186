import AccordionCustomComponent from "components/common/layouts/Accordion";
import { CopyButton } from "components/common/ui/Buttons/Copy-Button";
import { useSelector } from "react-redux";

export function AreWeOpenAccordion() {
    const uid = useSelector((state: any) => state.firebase.auth.uid);
    const link = `https://parks.touritickets.de/iFrames/currently-open/${uid}`

    return <AccordionCustomComponent title={"Are we Open?"}>
        <CopyButton
            value={link}
        />
    </AccordionCustomComponent>
}