import { Button, Grid, Paper, Typography } from "@mui/material";
import LoginForm from "./Login-Form";
import { useNavigate } from "react-router";

export function LoginPaperComponent() {
    const navigate = useNavigate();

    return <Paper elevation={3} style={{ maxWidth: '500px', textAlign: 'center', margin: 'auto', padding: '1rem', borderRadius: '1rem', opacity: '1', backgroundColor: "white", backdropFilter: 'blur(150px)', WebkitBackdropFilter: 'blur(15p0x)' }}>
        <Typography typography={"h1"}>Login</Typography>
        <LoginForm />
        <Grid container spacing={1} style={{marginTop: '1rem'}}>
            <Grid item xs={12} sm={6}>
                <Button variant="text" fullWidth onClick={()=>{navigate('/forgot-password')}}>Forgot Password</Button>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Button variant="text" fullWidth onClick={()=>{navigate('/registration')}}>Registration</Button>
            </Grid>
        </Grid>
    </Paper>
}