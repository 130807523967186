import { Button, Grid, TextareaAutosize } from "@mui/material";
import AccordionCustomComponent from "components/common/layouts/Accordion";
import { doc, updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { auth, db } from "utilities/Firebase/firebase-redux";

export default function MetaDescriptioneSettingsAccordion() {
    const uid = useSelector((state: any) => state.firebase.auth.uid);
    const park = useSelector((state: any) => state.firestore.ordered.park);

    const [metaDescriptionText, setMetaDescriptionText] = useState("");

    useEffect(() => {
        if (!park) return;
        setMetaDescriptionText(park[0]?.MetaDescription || "")
    }, [park])

    function submitHandler(event: any) {
        event.preventDefault();
        if (!uid || !auth?.currentUser) {
            toast.error("Not logged in");
            return;
        }
        const myDoc = doc(db, `/Parks/${uid}`)
        const newData = {
            MetaDescription: metaDescriptionText
        }
        updateDoc(myDoc, newData)
            .then((res) => {
                toast.success("Meta Description updated")
            })
            .catch((err) => {
                toast.error("Meta Description update error")
            })
    }

    return <AccordionCustomComponent title={"Meta Description"}>
        <form onSubmit={submitHandler}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <TextareaAutosize
                        required
                        value={metaDescriptionText}
                        onChange={(e) => { setMetaDescriptionText(e?.target?.value) }}
                        minRows={5}
                        style={{ width: "100%" }}
                        placeholder="Write your Park Description, but keep it short"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" fullWidth type="submit">Update</Button>
                </Grid>
            </Grid>
        </form>
    </AccordionCustomComponent>
}