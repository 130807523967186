import { Button, Grid, TextareaAutosize } from "@mui/material";
import AccordionCustomComponent from "components/common/layouts/Accordion";
import { doc, updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { db } from "utilities/Firebase/firebase-redux";

export default function ParkDescriptionComponent() {
    const uid = useSelector((state: any) => state.firebase.auth.uid);
    const park = useSelector((state: any) => state.firestore.ordered.park);

    const [longParkDescription, setLongParkDescription] = useState('')

    useEffect(() => {
        if (!park) return;
        setLongParkDescription(park[0]?.LongParkDescription || "")
    }, [park])

    function submitHandler(event: any) {
        event.preventDefault();
        if (!uid) {
            toast.error("Not logged in");
            return;
        }
        const myDoc = doc(db, `/Parks/${uid}`)
        const newData = {
            LongParkDescription: longParkDescription
        }
        updateDoc(myDoc, newData)
            .then(() => {
                toast.success("Updated Park Description")
            })
            .catch((err) => {
                toast.error("There was an error while Updating your Park Description")
            })
    }

    return <AccordionCustomComponent title={"Park Description"}>
        <form onSubmit={submitHandler}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <TextareaAutosize required value={longParkDescription} onChange={(e) => { setLongParkDescription(e?.target?.value) }} minRows={5} style={{ width: "100%" }} placeholder="Full Park Description in html format" />
                </Grid>

                <Grid item xs={12}>
                    <Button style={{ borderRadius: "1rem" }} variant="contained" fullWidth type="submit">Update</Button>
                </Grid>
            </Grid>
        </form>
    </AccordionCustomComponent>
}