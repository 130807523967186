import { Grid } from "@mui/material";
import BankInformation from "components/base/Settings/Business-Details/bank-information";
import BusinessInformation from "components/base/Settings/Business-Details/business-information";
import EnableDisablePaymentMethods from "components/base/Settings/Business-Details/enable-disable-payment-methods";
import StripeConnect from "components/base/Settings/Business-Details/stripe-connect";
import { PrivatePageLayout } from "components/common/layouts/Page/private";

export function PayoutInformationSettings() {
    return <PrivatePageLayout
        link={"https://images.pexels.com/photos/16737194/pexels-photo-16737194.jpeg?auto=compress&cs=tinysrgb"}
        activeLink={"Settings"}
        activeSubLink={"Payout Information"}
        title="Stripe"
    >
        <Grid container style={{ maxWidth: "500px", margin: 'auto' }} spacing={0}>
            <Grid item xs={12}>
                <EnableDisablePaymentMethods />
            </Grid>
            <Grid item xs={12}>
                <StripeConnect />
            </Grid>
            <Grid item xs={12}>
                <BankInformation />
            </Grid>
            <Grid item xs={12}>
                <BusinessInformation />
            </Grid>
            {/* <Grid item xs={12}>
                <TaxInformation />
            </Grid> */}
        </Grid>
    </PrivatePageLayout>
}