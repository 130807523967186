import { Button, TextField } from "@mui/material";
import { useEffect, useState } from "react";

export function PasswordTextField({setPassword, label, error = false, required = false, variant = "standard"}: 
    {setPassword: any; label: string; error?: boolean; required?: boolean, variant?: "standard" | "filled" | "outlined" | undefined}) {
    const [value, setValue] = useState('');
    const [isPasswordVisible, setPasswordIsVisible] = useState(false);

    useEffect(()=>{
        setPassword(value)
    }, [value])

    return <TextField 
        fullWidth 
        required={required} 
        error={error} 
        value={value} 
        variant={variant}
        onChange={(e)=>{setValue(e?.target?.value)}} 
        label={label} 
        type={isPasswordVisible ? "text" : "password"} 
        InputProps={{endAdornment: <Button variant="text" onClick={()=>{setPasswordIsVisible(!isPasswordVisible)}}>{isPasswordVisible?"HIDE":"SHOW"}</Button>}} 
    />
}