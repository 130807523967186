import AccordionCustomComponent from "components/common/layouts/Accordion";
import { CopyButton } from "components/common/ui/Buttons/Copy-Button";
import { useSelector } from "react-redux";

export function ParkEventProcess() {
    const uid = useSelector((state: any) => state.firebase.auth.uid);
    const link = `https://tickets.touritickets.de/event-process/?parkId=${uid}/`

    return <AccordionCustomComponent title={"TouriTickets - Park Event-Process"}>
        <CopyButton
            value={link}
        />
    </AccordionCustomComponent>
}