import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const now = new Date();
const today = new Date();
today.setHours(0);
today.setMinutes(0);
today.setSeconds(0);
today.setMilliseconds(0);

export default function TodayOpen({businessHours}: 
    {businessHours: any,}) {

    const [startTime, setStartTime] = useState<Date>(new Date())
    const [endTime, setEndTime] = useState<Date>(new Date())

    useEffect(()=>{
        if(!businessHours) return;
        businessHours?.forEach((bHour: any)=>{
            const bHourDate = new Date(bHour?.Date);
            if(bHourDate.getTime() !== today.getTime()) return;

            const startTimeLocal = new Date(bHour?.NewStartTime ? bHour?.NewStartTime : bHour?.StartTime);
            const endTimeLocal = new Date(bHour?.NewEndTime ? bHour?.NewEndTime : bHour?.EndTime);
            
            const startTimeToday = new Date(today);
            startTimeToday.setHours(startTimeLocal.getHours());
            startTimeToday.setMinutes(startTimeLocal.getMinutes());
            startTimeToday.setSeconds(startTimeLocal.getSeconds());
            startTimeToday.setMilliseconds(startTimeLocal.getMilliseconds());

            const endTimeToday = new Date(today);
            endTimeToday.setHours(endTimeLocal.getHours());
            endTimeToday.setMinutes(endTimeLocal.getMinutes());
            endTimeToday.setSeconds(endTimeLocal.getSeconds());
            endTimeToday.setMilliseconds(endTimeLocal.getMilliseconds());
            
            setStartTime(startTimeToday)
            setEndTime(endTimeToday)
        })
    }, [businessHours])
    
    return <span style={{ margin: 'auto'}}>
        {now.getTime() <= endTime.getTime() && now.getTime() >= startTime.getTime() ? "Wir haben heute geöffnet" : "Wir haben heute geschlossen"}
    </span>
}