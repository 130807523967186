import { Grid } from "@mui/material";
import { GoogleAccordion } from "components/base/Settings/Connected-Accounts/Google";
import { WebhookAccordion } from "components/base/Settings/Connected-Accounts/Webhooks";
import { PrivatePageLayout } from "components/common/layouts/Page/private";

export function ConnectedAccountsSettingsPage() {
    return <PrivatePageLayout
        link={"https://images.pexels.com/photos/16110121/pexels-photo-16110121.jpeg?auto=compress&cs=tinysrgb"}
        activeLink={"Settings"}
        activeSubLink={"Connected Accounts"}
        title="Connected Accounts"
    >
        <Grid container style={{ maxWidth: "500px", margin: 'auto' }} spacing={0}>
            <Grid item xs={12}>
                <WebhookAccordion />
            </Grid>
            <Grid item xs={12}>
                <GoogleAccordion />
            </Grid>
        </Grid>
    </PrivatePageLayout>
}