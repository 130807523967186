import { MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import CURRENCIES from "../../../../../data/currencies.json"

export default function CurrencyDropdown({setCurrency, defaultCurrency = "EUR", required}: 
    {setCurrency: any; defaultCurrency?: string; required?: boolean}) {
    const [select, setSelect] = useState(defaultCurrency)

    useEffect(()=>{
        setCurrency(select);
    }, [select])

    return <Select required={required} fullWidth value={select} onChange={(e: any) => { setSelect(e.target.value); }}>
        {CURRENCIES?.map((currency)=>{
            return <MenuItem disabled={currency.cc !== "EUR"} value={currency.cc} key={`Language ${currency.cc}`}>{currency.name}</MenuItem>
        })}
    </Select>;
}