import { Avatar, Divider, Drawer as MuiDrawer, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Typography, ListItemIcon, styled, Theme, CSSObject, AppBar, Toolbar, IconButton, Hidden } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from "../../../utilities/Firebase/firebase-redux";
import { useSelector } from "react-redux";
import { AuthProtection } from "../../../utilities/Auth-Protection/protection";
import { AnalyticsTwoTone, CloseOutlined, Menu } from "@mui/icons-material";
import GridViewIcon from '@mui/icons-material/GridViewTwoTone';
import EventIcon from '@mui/icons-material/EventTwoTone';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumberTwoTone';
import SettingsIcon from '@mui/icons-material/SettingsTwoTone';
import LogoutIcon from '@mui/icons-material/LogoutTwoTone';
import DrawerListItemDropdown from "./list-item-dropdown";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop: any) => prop !== 'open' })(
    ({ theme, open }: { theme: any, open: boolean }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export function ParkNavigation({ visible = false, activeLink, activeSubLink, title }:
    { visible?: boolean, activeLink: string, activeSubLink?: string, title: string }) {
    const park = useSelector((state: any) => state.firestore.ordered.park);

    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
    const [isHoursAndTicketsOpen, setIsHoursAndTicketsOpen] = useState<boolean>(false);
    const [isEventsOpen, setIsEventsOpen] = useState<boolean>(false);
    const [isSettingsOpen, setIsSettingsOpen] = useState<boolean>(false);
    const [companyName, setCompanyName] = useState('');
    const [displayName, setDisplayName] = useState("");
    const [profilePicture, setProfilePicture] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        if (!park) return;
        setCompanyName(park[0]?.ParkTitle)
    }, [park])

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            setProfilePicture(user?.photoURL || "");
            setDisplayName(user?.displayName || "");
        })
    }, [auth])

    return <>
        <AuthProtection />
        {!visible && <AppBar position="sticky" style={{ backgroundColor: "white", borderBottom: "1px solid black", borderRadius: "0 0 1rem 1rem" }}>
            <Toolbar variant="dense">
                <IconButton size="small" edge="start" aria-label="menu" sx={{ mr: 2 }} onClick={() => { setIsDrawerOpen(!isDrawerOpen) }}>
                    <Menu />
                </IconButton>
                <Typography variant="h1" component="div" sx={{ flexGrow: 1, color: "black" }}>
                    {title}
                </Typography>

                <Hidden smDown>
                    <Typography variant="body1" component="div" sx={{ color: "black" }}>
                        {companyName}
                    </Typography>
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={() => { setIsDrawerOpen(true); }}
                        color="inherit"
                    >
                        <Avatar src={profilePicture} />
                    </IconButton>
                </Hidden>
            </Toolbar>
        </AppBar>}
        <MuiDrawer
            variant="persistent"
            anchor="left"
            open={isDrawerOpen}
            PaperProps={{ style: { borderRadius: "0 1rem 1rem 0" } }}
        >
            <div style={{ display: 'flex', padding: '1rem 0.5rem' }}>
                <ListItemAvatar>
                    <Avatar src={profilePicture} />
                </ListItemAvatar>
                <ListItemText>
                    <Typography variant="h6">{displayName}</Typography>
                </ListItemText>
                <IconButton onClick={() => { setIsDrawerOpen(false); }}>
                    <CloseOutlined />
                </IconButton>
            </div>

            <Divider />

            <List>
                <ListItemButton>
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: isDrawerOpen ? 3 : 'auto',
                            justifyContent: 'center',
                        }}
                    >
                        <GridViewIcon style={{ color: activeLink === "Dashboard" ? 'green' : 'black' }} />
                    </ListItemIcon>
                    <ListItemText primary={"Dashboard"} style={{ color: activeLink === "Dashboard" ? 'green' : 'black' }} onClick={() => { navigate('/'); }} />
                </ListItemButton>



                {/* Analytics Dropdown */}

                <DrawerListItemDropdown
                    Icon={AnalyticsTwoTone}
                    isListItemSelected={activeLink === "analytics"}
                    title={"Analytics"}
                >
                    <ListItemButton sx={{ pl: 4 }} disabled>
                        <ListItemText style={{ color: activeSubLink === "analytics" ? 'green' : 'black' }} primary={"Analytics"} onClick={() => { navigate('/analytics'); }} />
                    </ListItemButton>
                </DrawerListItemDropdown>


                {/* Tickets, Prices and Timings Dropdown */}

                <DrawerListItemDropdown
                    Icon={ConfirmationNumberIcon}
                    isListItemSelected={activeLink === "tickets,prices,times"}
                    title={"Tickets, Preise, Zeiten"}
                >
                    <ListItemButton sx={{ pl: 4 }}>
                        <ListItemText style={{ color: activeSubLink === "tickets" ? 'green' : 'black' }} primary={"Tickets"} onClick={() => { navigate('/hoursandtickets/tickets'); }} />
                    </ListItemButton>

                    <ListItemButton sx={{ pl: 4 }}>
                        <ListItemText style={{ color: activeSubLink === "prices" ? 'green' : 'black' }} primary={"Preise"} onClick={() => { navigate('/hoursandtickets/prices'); }} />
                    </ListItemButton>

                </DrawerListItemDropdown>


                {/* Calender Dropdown */}

                <DrawerListItemDropdown
                    Icon={EventIcon}
                    isListItemSelected={activeLink === "calendar"}
                    title={"Kalender"}
                >
                    <ListItemButton sx={{ pl: 4 }}>
                        <ListItemText style={{ color: activeSubLink === "business-hours" ? 'green' : 'black' }} primary={"Öffnungszeiten"} onClick={() => { navigate('/calendars/businesshours'); }} />
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 4 }}>
                        <ListItemText style={{ color: activeSubLink === "Edit Hours" ? 'green' : 'black' }} primary={"Edit Hours & Tickets"} onClick={() => { navigate('/calendars/edit-days'); }} />
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 4 }}>
                        <ListItemText style={{ color: activeSubLink === "events-calendar" ? 'green' : 'black' }} primary={"Events Kalender"} onClick={() => { navigate('/calendars/events-calendar'); }} />
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 4 }} disabled>
                        <ListItemText style={{ color: activeSubLink === "employee-calendar" ? 'green' : 'black' }} primary={"Mitarbeiter Calendar"} onClick={() => { navigate('/calendars/employee-calendar'); }} />
                    </ListItemButton>
                </DrawerListItemDropdown>


                {/* Settings Dropdown */}

                <DrawerListItemDropdown
                    Icon={SettingsIcon}
                    isListItemSelected={activeLink === "Settings"}
                    title={"Settings"}
                >
                    <ListItemButton sx={{ pl: 4 }}>
                        {/* <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: isDrawerOpen ? 3 : 'auto',
                                justifyContent: 'center',
                            }}
                        >
                            <PermMediaTwoToneIcon style={{ color: activeLink === "Media Library" ? 'green' : 'black' }} />
                        </ListItemIcon> */}
                        <ListItemText style={{ color: activeSubLink === "media-library" ? 'green' : 'black' }} primary={"Media Library"} onClick={() => { navigate('/settings/media-library'); }} />
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 4 }}>
                        <ListItemText style={{ color: activeSubLink === "General Information" ? 'green' : 'black' }} primary={"Gerneral Information"} onClick={() => { navigate('/settings/basic-information'); }} />
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 4 }}>
                        <ListItemText style={{ color: activeSubLink === "Payout Information" ? 'green' : 'black' }} primary={"Payout Information"} onClick={() => { navigate('/settings/payout-information'); }} />
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 4 }}>
                        <ListItemText style={{ color: activeSubLink === "Localization Information" ? 'green' : 'black' }} primary={"Localization Information"} onClick={() => { navigate('/settings/localization'); }} />
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 4 }}>
                        <ListItemText style={{ color: activeSubLink === "Connected Accounts" ? 'green' : 'black' }} primary={"Connected Accounts"} onClick={() => { navigate('/settings/accounts'); }} />
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 4 }}>
                        <ListItemText style={{ color: activeSubLink === "Email Templates" ? 'green' : 'black' }} primary={"Email Templates"} onClick={() => { navigate('/settings/email-templates'); }} />
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 4 }}>
                        <ListItemText style={{ color: activeSubLink === "iFrames" ? 'green' : 'black' }} primary={"iFrames"} onClick={() => { navigate('/settings/iFrames'); }} />
                    </ListItemButton>
                </DrawerListItemDropdown>

                <ListItem onClick={() => { signOut(auth).then((e) => { navigate('/login') }).catch((err) => { console.log({ err }) }) }} disablePadding>
                    <ListItemButton>
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: isDrawerOpen ? 3 : 'auto',
                                justifyContent: 'center',
                            }}
                        >
                            <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Logout"} />
                    </ListItemButton>
                </ListItem>
            </List>
        </MuiDrawer>
    </>;
}