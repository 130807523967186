import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function AccordionCustomComponent({ title, children }: { title: string, children?: any }) {
    return <Accordion elevation={3} style={{ borderRadius: "1rem", textAlign: "center", margin: '0.5rem 0' }}>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
        >
            <Typography typography={"h3"}>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
            {children}
        </AccordionDetails>
    </Accordion>
}