import { Button, Grid, TextField, Typography } from "@mui/material";
import AccordionCustomComponent from "components/common/layouts/Accordion";
import CountryDropdown from "components/common/ui/Selects/Country-Select/country-select";
import CurrencyDropdown from "components/common/ui/Selects/Currency-Select/currency-select";
import { doc, setDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { db } from "utilities/Firebase/firebase-redux";

export default function BankInformation() {
    const uid = useSelector((state: any) => state.firebase.auth.uid);
    const payoutInfo = useSelector((state: any) => state.firestore.ordered.payout_info);
    
    const [country, setCountry] = useState('')
    const [accountHolderName, setAccountHolderName] = useState('');
    const [accountNumber, setAccountNumber] = useState('')
    const [currency, setCurrency] = useState('EUR')
    const [bic, setBIC] = useState('')

    useEffect(()=>{
        if(!payoutInfo) return;
        setCountry(payoutInfo[0]?.Country || "")
        setAccountHolderName(payoutInfo[0]?.AccountHolderName || "")
        setAccountNumber(payoutInfo[0]?.AccountNumber || "")
        setCurrency(payoutInfo[0]?.Currency || "EUR")
        setBIC(payoutInfo[0]?.BIC || "")
    }, [payoutInfo])

    async function updatePayoutBankingInformation(data: any) {
        const myDoc = doc(db, `Parks/${uid}/stripe_connect/payout_info`);
        return await setDoc(myDoc, data, {merge: true});
    }

    async function saveAndExit(event: any) {
        event.preventDefault();
        if(!uid) {
            toast.error("Not logged in");
            return;
        }

        // const createStripeConnectCloudFunction = httpsCallable(functions, "onUpdateBankDetails");
        // createStripeConnectCloudFunction({
        //     Country: country,
        //     AccountHolderName: accountHolderName,
        //     AccountNumber: accountNumber,
        //     Currency: currency,
        //     BIC: bic,
        // })
        updatePayoutBankingInformation({
            Country: country,
            AccountHolderName: accountHolderName,
            AccountNumber: accountNumber,
            Currency: currency,
            BIC: bic,
        })
            .then((res)=>{
                toast.success("Updated Bank Details")
            })
            .catch((err)=>{
                toast.error("There was an error while Updating your Bank Details")                
            })
    }


    return <AccordionCustomComponent title={"Payout Details"}>
        <form onSubmit={saveAndExit}> 
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <Typography>Select Country your Bank is located in</Typography>
                    <CountryDropdown key={`Country Dropdown ${country}`} setCountry={setCountry} defaultCountry={country} />
                </Grid>
                <Grid item xs={6}>
                    <Typography>Select Currency your Bank Account is using</Typography>
                    <CurrencyDropdown setCurrency={setCurrency} defaultCurrency={currency}/>
                </Grid>
                <Grid item xs={12}>
                    <TextField fullWidth label="Account Holder Name" size="small" value={accountHolderName} onChange={(e)=>{setAccountHolderName(e?.target?.value)}}/>
                </Grid>
                <Grid item xs={12}>
                    <TextField fullWidth label="Account Number // IBAN" size="small" value={accountNumber} onChange={(e)=>{setAccountNumber(e?.target?.value)}}/>
                </Grid>
                <Grid item xs={12}>
                    <TextField fullWidth label="Routing Number // BIC" size="small" value={bic} onChange={(e)=>{setBIC(e?.target?.value)}}/>
                </Grid>

                <Grid item xs={12}>
                    <Button fullWidth variant="contained" type="submit">Update</Button>
                </Grid>
            </Grid>
        </form>
    </AccordionCustomComponent>
}