import { GoogleOAuthProvider } from "@react-oauth/google";
import ConnectedAccountGoogle from "./google";
import AccordionCustomComponent from "components/common/layouts/Accordion";

const googleClientId = "269905809648-s1g0tpo1287tkpkni9ahbvfo1khrf1le.apps.googleusercontent.com"

export function GoogleAccordion() {
    return <AccordionCustomComponent title={"Google"}>
            <GoogleOAuthProvider clientId={googleClientId}>
                <ConnectedAccountGoogle />
            </GoogleOAuthProvider>
        </AccordionCustomComponent>
}